import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory highlight
 * @namespace slice:deleteMultiHighlightsSlice
 * @description deleteMultiHighlightsSlice is slice for deleting book highlights.
 */

/**
 * @memberof slice:deleteMultiHighlightsSlice
 * @method addHighlights
 * @async
 * @description Rest api for deleting book highlights.
 * @param {Array<Object>} data {id:int, cfi:string}.
 */

export const deleteMultiHighlights = createAsyncThunk(
  "book/deleteMultiHighlights",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      // const token = getState().userToken.data.access_token;
      const token = WebSession()?.access_token;
      let deleteHighlightArray = [];
      let response = null;
      for (let i = 0; i < data.length; i++) {
        response = await myAxios(
          token,
          getState().saveUserUniqueId.userUniqueId
        ).delete(`api/highlight/${data[i].id}`);
        if (response.status !== 200) break;
        deleteHighlightArray.push(data[i].cfi);
      }
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return deleteHighlightArray;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:deleteMultiHighlightsSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const deleteMultiHighlightsSlice = createSlice({
  name: "deleteMultiHighlights",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteMultiHighlights.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteMultiHighlights.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(deleteMultiHighlights.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:deleteMultiHighlightsSlice
     * @method clearDeleteMultiHighlights
     * @description Clear the deleteMultiHighlightsSlice.
     */
    clearDeleteMultiHighlights: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearDeleteMultiHighlights } =
  deleteMultiHighlightsSlice.actions;

export default deleteMultiHighlightsSlice.reducer;
