import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { autoDecrementCount, clearCount } from "../reloaderSlice";
import { BookShelfId } from "../../../config";

/**
 * @category Redux
 * @subcategory favoriteSlice
 * @namespace slice:getAllFavoriteBooksSlice
 * @description getAllFavoriteBooksSlice is slice for getting favorite bookshelf.
 */

/**
 * @memberof slice:getAllFavoriteBooksSlice
 * @method getAllFavoriteBooks
 * @async
 * @description Rest api to getting favorite bookshelf.
 *
 */

export const getAllFavoriteBooks = createAsyncThunk(
  "user/getAllFavoriteBooks",
  async (data, { rejectWithValue, getState, dispatch }) => {
    return await recursion(data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const token = WebSession()?.access_token;
    const response = await myAxios(
      token,
      getState().saveUserUniqueId.userUniqueId
    ).get(`api/bookshelf/${BookShelfId.FAVORITE_ID}`);
    if (response.status === 200) {
      dispatch(clearCount("getAllFavoriteBooks"));
      if (response.data.status.statusCode === 200) {
        return response.data?.data ? response.data.data : [];
      } else {
        return rejectWithValue(response.data.status.errorMessage);
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (err?.response?.status === 429) {
      dispatch(logout());
    } else if (getState().reloader["getAllFavoriteBooks"]) {
      dispatch(autoDecrementCount("getAllFavoriteBooks"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else {
      return rejectWithValue(err);
    }
  }
};

/**
 * @memberof slice:updateFavoriteBookSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getAllFavoriteBooksSlice = createSlice({
  name: "getAllFavoriteBooks",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllFavoriteBooks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllFavoriteBooks.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getAllFavoriteBooks.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getAllFavoriteBooksSlice
     * @method setSuccessFavorites
     * @description Success response.
     * @param {object} payload bookshelf data
     */
    setSuccessFavorites: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getAllFavoriteBooksSlice
     * @method setFavoriteBookDetails
     * @description this method is to add book details grid to existing favorite bookshelf.
     * @param {object} payload {bookId:string, bookshelfId:string, numberOfGridPerRow:int}
     */
    setFavoriteBookDetails: (state, action) => {
      const { bookshelfId, bookId, numberOfGridPerRow } = action.payload;
      const _book = state.data.books.find((x) => x.id === bookId);
      state.data.books = state.data.books.filter((x) => !x?.isClicked);
      const _index = state.data.books.findIndex((x) => x.id === bookId);
      const _gridIndex =
        (Math.floor(_index / numberOfGridPerRow) + 1) * numberOfGridPerRow;
      state.data.books.splice(_gridIndex, 0, {
        ..._book,
        isClicked: true,
      });
      state.data = {
        ...state.data,
        clickId: `${bookshelfId}-${bookId}`,
      };
    },
    /**
     * @memberof slice:getAllFavoriteBooksSlice
     * @method resetFavoriteBookDetails
     * @description Close or reset the book details grid in favorite bookshelf.
     */
    resetFavoriteBookDetails: (state, action) => {
      try {
        state.data.books = state.data?.books?.filter((x) => !x?.isClicked);
        state.data = {
          ...state.data,
          clickId: "",
        };
      } catch (ex) {}
    },
  },
});

export const {
  setSuccessFavorites,
  setFavoriteBookDetails,
  resetFavoriteBookDetails,
} = getAllFavoriteBooksSlice.actions;

export default getAllFavoriteBooksSlice.reducer;
