import React, { Suspense } from 'react';
import Loader from './Loader';

/**
 * @category UiComponents
 * @namespace Loadable
 * @description Custom loader for showing loader for lazy component.
 * @param {React.FC} Component Child component
 * @param {Object} props Default props
 * @class
 * @component
 * 
 * @property {React.FC} Loader
 */
const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );

export default Loadable;
