/**
 * @category Themes
 * @namespace themePalette
 * @description mui theme palettes.
 * @property {JsonObject} theme Theme customization object
 */
export default function themePalette(theme) {
  return {
    mode: "light",
    common: {
      black: "#000000",
      white: "#ffffff",
      shadeWhite: "#fafafa",
      borderGrey: "rgba(0, 0, 0, 0.23)",
      helperTextColor: "#616161",
      red: "#ff0000",
      orange: "#FFA500",
      abstractColor: "#000000DE",
      menuGray: "rgba(0, 0, 0, 0.54)",
      dashBoardBorderGrey: "rgba(0, 0, 0, 0.20)",
      darkGreen:'#009252', //#009252
      green:'#00cc5f',
      closeRed:"#d60000",
      searchPage:"#73b8d28c",
      gold: '#FFD700',
      darkBlue: "#2e87db",
      darkOrange: "#ff8a00",
      spinnerBg:'#ffffff77',
    },
    primary: {
      light: theme.colors.primaryLight,
      main: theme.colors.primaryMain,
      dark: theme.colors.primaryDark,
      200: theme.colors.primary200,
      800: theme.colors.primary800,
    },
    secondary: {
      light: theme.colors.secondaryLight,
      main: theme.colors.secondaryMain,
      dark: theme.colors.secondaryDark,
      200: theme.colors.secondary200,
      800: theme.colors.secondary800,
    },
    error: {
      light: theme.colors.errorLight,
      main: theme.colors.errorMain,
      dark: theme.colors.errorDark,
    },
    orange: {
      light: theme.colors.orangeLight,
      main: theme.colors.orangeMain,
      dark: theme.colors.orangeDark,
    },
    warning: {
      light: theme.colors.warningLight,
      main: theme.colors.warningMain,
      dark: theme.colors.warningDark,
    },
    success: {
      light: theme.colors.successLight,
      200: theme.colors.success200,
      main: theme.colors.successMain,
      dark: theme.colors.successDark,
    },
    grey: {
      50: theme.colors.grey50,
      100: theme.colors.grey100,
      500: theme.darkTextSecondary,
      600: theme.heading,
      700: theme.darkTextPrimary,
      900: theme.textDark,
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      dark: theme.textDark,
      hint: theme.colors.grey100,
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault,
    },
  };
}
