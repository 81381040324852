import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState, API_CONSTANTS } from "../../utils";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import axios from "axios";
import { URI } from "../../../config";

/**
 * @category Redux
 * @subcategory highlight
 * @namespace slice:getHighlightByIdSlice
 * @description getHighlightByIdSlice is slice for getting the highlights by book id.
 */

/**
 * @memberof slice:getHighlightByIdSlice
 * @method getHighlightById
 * @async
 * @description Rest api for getting the highlights by book id.
 * @param {string} bookId Book id.
 * @param {boolean} isInitial Initial trigger flag.
 */

export const getHighlightById = createAsyncThunk(
  "book/getHighlightById",
  async (
    { bookId, isInitial },
    { rejectWithValue, getState, dispatch, signal }
  ) => {
    try {
      const token = WebSession()?.access_token;
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`api/highlight/${bookId}`, {
        baseURL: URI.BASE_URL,
        headers: {
          Authorization: "Bearer " + token,
          "user-unique-id": getState().saveUserUniqueId.userUniqueId,
        },
        cancelToken: source.token,
      });
      signal.removeEventListener("abort", null);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return {
            bookId,
            data: response.data?.data,
            isInitial,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      signal.removeEventListener("abort", null);
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getHighlightByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {Array<Object>} cachedData Cache the highlights using book id.
 */

export const getHighlightByIdSlice = createSlice({
  name: "getHighlightById",
  initialState: {
    ...initialState,
    cachedData: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getHighlightById.pending, (state, { meta }) => {
      const { bookId, isInitial } = meta.arg;
      const cachedData = state.cachedData?.find((val) => val.bookId === bookId);
      if (isInitial && cachedData) {
        state.data = cachedData?.data;
        state.hasData = true;
        state.hasError = false;
        state.error = null;
      } else {
        state.isLoading = true;
      }
    });

    builder.addCase(getHighlightById.fulfilled, (state, action) => {
      const { bookId, data } = action.payload;
      state.hasData = true;
      state.data = data;
      state.hasError = false;
      state.isLoading = false;
      state.error = null;

      const index = state.cachedData?.findIndex((val) => val.bookId === bookId);
      if (index === -1) {
        state.cachedData.push({
          bookId,
          data,
        });
      } else {
        state.cachedData[index] = { bookId, data };
      }
    });

    builder.addCase(getHighlightById.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getHighlightByIdSlice
     * @method setHighlightCachedData
     * @description Caching the highlights using book id.
     * @param {Object} payload {bookId:string}
     */
    setHighlightCachedData: (state, action) => {
      const { bookId } = action.payload;
      const index = state.cachedData?.findIndex((val) => val.bookId === bookId);
      if (index > -1) {
        state.cachedData?.splice(index, 1);
      }
    },
    /**
     * @memberof slice:getHighlightByIdSlice
     * @method clearHighlightCachedData
     * @description Clear all cache data.
     */
    clearHighlightCachedData: (state, action) => {
      state.cachedData = [];
    },
    /**
     * @memberof slice:getHighlightByIdSlice
     * @method clearGetHighlightById
     * @description Clear the getHighlightByIdSlice.
     */
    clearGetHighlightById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
    /**
     * @memberof slice:getHighlightByIdSlice
     * @method setSuccessGetHighlightByBookId
     * @description Success response.
     * @param {Object} payload Highlight data.
     */
    setSuccessGetHighlightByBookId: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getHighlightByIdSlice
     * @method setErrorGetHighlightByBookId
     * @description Error response.
     * @param {string} payload Error data
     */
    setErrorGetHighlightByBookId: (state, action) => {
      state.hasData = false;
      state.hasError = true;
      state.error = action.payload;
      state.data = null;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getHighlightByIdSlice
     * @method clearAllGetHighlightById
     * @description Clear the getHighlightByIdSlice.
     */
    clearAllGetHighlightById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.cachedData = [];
    },
  },
});

export const {
  clearGetHighlightById,
  setHighlightCachedData,
  clearHighlightCachedData,
  setSuccessGetHighlightByBookId,
  setErrorGetHighlightByBookId,
  clearAllGetHighlightById,
} = getHighlightByIdSlice.actions;

export default getHighlightByIdSlice.reducer;
