import axios from "axios";
import { URI } from "../../config/userManager";

/**
 * @category Redux
 * @subcategory Utils
 * @namespace Redux:Axios
 * @description Axios setup.
 */

/**
 * @memberof Redux:Axios
 * @property {Object} axiosCancelSource Cancel the axios request.
 */
export const axiosCancelSource = axios.CancelToken.source();

/**
 * @memberof Redux:Axios
 * @method myAxios
 * @description Create axios client.
 * @param {string} token User access token.
 * @param {string} userUniqueId User unique id for session handling.
 */
export const myAxios = (token, userUniqueId) => {
  return axios.create({
    baseURL: URI.BASE_URL,
    headers: {
      Authorization: "Bearer " + token,
      "user-unique-id": userUniqueId ? userUniqueId : "",
    },
  });
};
