import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { AppString } from "../../config";
import { setUserLogout } from "../../redux/slice/auth/logoutSlice";
import AppTooltip from "../common/AppTooltip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    backgroundColor: "#fff",
    boxShadow: 24,
    padding: "32px 40px",
    [theme.breakpoints.up("x600")]: {
      width: "25rem",
    },
    [theme.breakpoints.down("x600")]: {
      width: "20rem",
    },
    borderRadius: 10,
  },
  footer: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-around",
    marginTop: 32,
  },
  footerButton: {
    textTransform: "none",
    fontSize: 16,
    padding: "8px 64px",
    maxWidth: 128,
    [theme.breakpoints.down("x600")]: {
      padding: "8px 32px",
      maxWidth: 86,
    },
  },
  modalTitle: {},
  modalMessage: {
    fontSize: 28,
    fontWeight: "bold",
    [theme.breakpoints.down("x600")]: {
      fontSize: 24,
    },
  },
  contentMessage: {
    fontSize: 20,
  },
  contentBox: {
    marginTop: theme.strictParam,
  },
  closeIcon: {
    color: "#d60000",
    right: "30px",
    cursor: "pointer",
  },
  closeButton: {
    position: "absolute",
    top: 6,
    right: 6,
    zIndex: "99",
  },
  closeContainer: {},
}));

/**
 * @category UiComponents
 * @subcategory Session
 * @namespace LogoutPopup
 * @description User logout popup modal. When click on logout or when session expired this modal will popup.
 * @param {Function} handleLogout On logout method to perform logout operation
 * @class
 * @component
 */
export default function LogoutPopup({ handleLogout }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sessionLogout = useSelector((state) => state.logout.sessionLogout);
  const userLogout = useSelector((state) => state.logout.userLogout);

  /**
   * @memberof LogoutPopup
   * @method handleClose
   * @description On handle the close of the logout modal
   */
  const handleClose = () => {
    if (userLogout) {
      handleCancel();
    }
  };

  /**
   * @memberof LogoutPopup
   * @method handleCancel
   * @description On handle the cancel inside logout modal
   */
  const handleCancel = () => {
    dispatch(setUserLogout(false));
  };

  return (
    <Modal
      open={sessionLogout || userLogout}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      style={{ zIndex: 2000 }}
    >
      {sessionLogout ? (
        <Box className={classes.container}>
          <Typography className={classes.modalMessage}>
            {AppString.logoutPopup.title}
          </Typography>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentMessage}>
              {AppString.logoutPopup.content}
            </Typography>
          </Box>
          <Box className={classes.footer}>
            <Button
              className={classes.footerButton}
              variant="contained"
              disableRipple
              onClick={handleLogout}
            >
              {AppString.logoutPopup.yes}
            </Button>
          </Box>
        </Box>
      ) : userLogout ? (
        <Box className={classes.container}>
          <Typography className={classes.modalMessage}>
            {AppString.logoutPopup.logoutConfirmation}
          </Typography>
          <Box className={classes.footer}>
            <Button
              className={classes.footerButton}
              variant="contained"
              disableRipple
              onClick={handleLogout}
            >
              {AppString.logoutPopup.yes}
            </Button>
            <Button
              className={classes.footerButton}
              variant="contained"
              disableRipple
              onClick={handleCancel}
            >
              {AppString.logoutPopup.cancel}
            </Button>
          </Box>
          <AppTooltip title={AppString.tooltip.close}>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <HighlightOffIcon className={classes.closeIcon} />
            </IconButton>
          </AppTooltip>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  );
}
