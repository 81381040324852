import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SVG from "react-inlinesvg";

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace RenderHeading
 * @description Feedback header component.
 * @param {string} content Header content
 * @param {Object} classes Css class name
 *
 */
const RenderHeading = ({ classes, content }) => {
  return (
    <h2 className={classes.feedbackTitle} id="simple-modal-title">
      {content}
    </h2>
  );
};

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace RenderTextBody
 * @description Feedback body component.
 * @param {string} content.line1 Body text
 * @param {string} content.line2 Body text
 * @param {string} content.line3 Body text
 * @param {string} content.line4 Body text
 * @param {string} content.kundService Custom kund service text
 * @param {Object} classes Css class name
 * @param {string} externalLink External url
 *
 */
const RenderTextBody = ({ classes, content, externalLink }) => {
  return (
    <>
      <p className={classes.feedbackText}>{content.line1}</p>
      <p className={classes.feedbackText}>{content.line2}</p>
      <br />
      <p className={classes.feedbackText}>{content.line3}</p>
      <br />
      <p className={classes.feedbackText}>
        <span className={classes.feedbackTextSpan}>({content.line4}</span>
        <span className={classes.feedbackTextSpan}>
          <a
            className={classes.feedbackTextATag}
            target="_blank"
            href={externalLink}
            rel="noopener"
          >
            {" "}
            {content.kundService}
          </a>
        </span>
        <span className={classes.feedbackTextSpan}>.)</span>
      </p>
    </>
  );
};

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace RenderTextField
 * @description Feedback user input component.
 * @param {string} content.placeHolder placeholder text
 * @param {Function} onHandleText On text change method.
 * @param {Object} classes Css class name
 * @param {string} error Input error message.
 *
 */
const RenderTextField = ({ classes, content, onHandleText, error }) => {
  return (
    <>
      <TextField
        id="outlined-basic"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        multiline={true}
        minRows={5}
        rows={5}
        fullWidth={true}
        onChange={onHandleText}
        placeholder={content.placeHolder}
        InputProps={{
          classes: {
            input: classes.inputField,
          },
        }}
        inputProps={{
          spellCheck: false,
        }}
      />
      <p className={classes.errorText}>{error}</p>
    </>
  );
};

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace RenderScreenShot
 * @description Feedback user screenshot component.
 * @param {string} content.title Component title.
 * @param {string} content.buttonText Button text.
 * @param {Function} onFileHandle On file change method.
 * @param {Object} classes Css class name
 * @param {string} fileSizeError File size error message.
 * @param {string} filename User filename.
 *
 */
const RenderScreenShot = ({
  classes,
  content,
  onFileHandle,
  fileSizeError,
  filename,
}) => {
  return (
    <>
      <p className={classes.ssName}>{content.title}</p>
      <div className={classes.sizeDisclaimerContainer}>
        <InfoIcon className={classes.sizeDisclaimerIcon} />
        <p className={classes.sizeDisclaimerText}>{content.title}</p>
      </div>
      <input
        className={classes.fileInputField}
        accept="image/*"
        id="feedback-contained-button-file"
        type="file"
        onChange={onFileHandle}
        size={1}
      />
      <div className={classes.fileInputWrapper}>
        <label
          className={classes.fileInputButton}
          htmlFor="feedback-contained-button-file"
        >
          {content.buttonText}
        </label>
        <p className={classes.fileName}>{filename}</p>
      </div>
      <p className={classes.fileSizeError}>{fileSizeError}</p>
    </>
  );
};

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace RenderFeedbackFooter
 * @description Feedback footer component.
 * @param {string} content.submitButton Submit button text.
 * @param {string} content.cancelButton Cancel button text.
 * @param {Function} onSubmit On user feedback submit function
 * @param {Function} handleClose On user feedback close function
 * @param {Object} classes Css class name
 *
 */
const RenderFeedbackFooter = ({ classes, content, onSubmit, handleClose }) => {
  return (
    <>
      <Button
        className={classes.fileSubmitBtn}
        variant="contained"
        disableRipple
        onClick={onSubmit}
      >
        {content.submitButton}
      </Button>
      <Button
        className={classes.fileCancelBtn}
        variant="contained"
        color="secondary"
        disableRipple
        onClick={handleClose}
      >
        {content.cancelButton}
      </Button>
    </>
  );
};

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace RenderFeedbackSuccess
 * @description Feedback success component.
 * @param {string} content.title Title text.
 * @param {string} content.description Description text.
 * @param {string} content.buttonText Close button text.
 * @param {Function} handleClose On user feedback close function
 * @param {Object} classes Css class name
 * @param {string} successIcon SVG icon
 *
 */
const RenderFeedbackSuccess = ({
  classes,
  content,
  successIcon,
  handleClose,
}) => {
  return (
    <>
      <SVG src={successIcon}></SVG>
      <CheckCircleIcon className={classes.successCheck} />
      <h1 className={classes.successTextTitle}>{content.title}</h1>
      <p className={classes.successText}>{content.description}</p>
      <Button
        className={classes.successBtn}
        variant="contained"
        color="secondary"
        disableRipple
        onClick={handleClose}
      >
        {content.buttonText}
      </Button>
    </>
  );
};

export {
  RenderTextBody,
  RenderHeading,
  RenderTextField,
  RenderScreenShot,
  RenderFeedbackFooter,
  RenderFeedbackSuccess,
};
