import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

/**
 * @category Routes
 * @subcategory customSnackBar
 * @namespace CustomAlert
 * @description Custom snackbar alert.
 * @param {Object} message {id:int, severity:string, message:string}
 * @param {function} removeSnackBar To move snackbar from snackPack
 * @param {int} duration Duration in seconds.
 * @class
 * @component
 */

const CustomAlert = ({ message, removeSnackBar, duration }) => {
  useEffect(() => {
    setTimeout(function () {
      removeSnackBar(message.id);
    }, duration);
  }, [message.id]);

  /**
   * @memberof CustomAlert
   * @method handleAlertClose
   * @description Closing the snack bar.
   * @property {Event} event Close event.
   * @property {string} reason Click reason.
   */
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    removeSnackBar(message.id);
  };

  return (
    <Alert
      onClose={handleAlertClose}
      elevation={6}
      variant="filled"
      severity={message.severity}
    >
      {message.message}
    </Alert>
  );
};

CustomAlert.defaultProps = {
  message: {
    id: "",
    severity: "",
    message: "",
  },
  removeSnackBar: () => {},
  duration: 2000,
};

export default CustomAlert;
