import React, { memo, useEffect, useRef } from "react";
import { useAuth } from "oidc-react";
import { useDispatch, useSelector } from "react-redux";
import { companyInfo } from "../../redux/slice/auth/companyInfoSlice";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import { showBrowserSupportDialog } from "../../redux/slice/browserSupportSlice";
import { AppTracker } from "../../config";

const ValidPaths = [
  "/dashboard",
  "/search",
  "/notebook",
  "/notebook",
  "/notebook",
  "/reader",
];

/**
 * @category Routes
 * @subcategory component
 * @namespace GTMSetup
 * @description Setting up the gtm container.
 * @class
 * @component
 * return (
 *   <GTMSetup />
 * )
 */

const GTMSetup = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const oidcData = useAuth();
  const companyInfoData = useSelector((state) => state.companyInfo.data);
  const browserSupport = useSelector((state) => state.browserSupport);
  const isGTMActive = useRef(null);

  useEffect(() => {
    if (!browserSupport.cachedShow) {
      if (navigator.userAgent.indexOf("Firefox") > 0) {
        dispatch(showBrowserSupportDialog());
      }
    }
  }, [browserSupport.cachedShow, dispatch]);

  useEffect(() => {
    const validPath = ValidPaths.filter((val) => {
      let isValidPath = location.pathname?.split("/")?.[1];
      return isValidPath ? val.includes(isValidPath) : false;
    });

    if (
      oidcData.userData &&
      !isGTMActive.current &&
      new Set([
        oidcData.userData?.profile?.userid,
        isGTMActive.current?.profile?.userid,
        companyInfoData?.userId?.toString(),
      ]).size !== 1 &&
      validPath.length > 0
    ) {
      onInitialFetch(oidcData.userData);
      isGTMActive.current = oidcData.userData;
    }
  }, [oidcData, location]);

  /**
 * @memberof GTMSetup
 * @method onInitialFetch
 * @async
 * @description Initial request for the GTMSetup component.
 * @property {Object} user User object from OIDC.
 */
  
  const onInitialFetch = async (user) => {
    if (
      !companyInfoData &&
      companyInfoData?.userId?.toString() !== user?.profile?.userid
    ) {
      const response = await dispatch(companyInfo(user?.access_token));
      if (response.meta.requestStatus === "fulfilled") {
        const { payload } = response;
        setTagManager(user, payload);
      }
    } else {
      setTagManager(user, companyInfoData);
    }
  };

    /**
 * @memberof GTMSetup
 * @method setTagManager
 * @async
 * @description Setting up the tag manger.
 * @property {Object} data User object from OIDC.
 * @property {Object} companyInfo This is from companyInfo slice
 */

  const setTagManager = (data, companyInfo) => {
    let tagManagerArgs = {
      dataLayer: {
        user_id: data?.profile?.userid,
        userProject: "project",
        page: "home",
        user_email: data?.profile.sub,
        user_name: data.profile.given_name,
        // activation_date: companyInfo.activationDate,
        account_id: companyInfo.customerId, //customer id
        account_name: companyInfo.companyName, //company name
      },
      gtmId: AppTracker.gtm,
    };
    TagManager.initialize(tagManagerArgs);
  };

  return <></>;
};

export default memo(GTMSetup);
