import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { updateBookForFavorite } from "../bookShelfSlice/getAllBookList";
import { AppString, BookShelfId } from "../../../config";
import { updatePersonalBookForFavorite } from "../personalBookShelfSlice";
import { updateDeprecatedBookForFavorite } from "../deprecatedBooks/getDeprecatedBooksSlice";

/**
 * @category Redux
 * @subcategory favoriteSlice
 * @namespace slice:updateFavoriteBookSlice
 * @description updateFavoriteBookSlice is slice for updating the favorite flag for book.
 */

/**
 * @memberof slice:updateFavoriteBookSlice
 * @method updateFavoriteBook
 * @async
 * @description Rest api to getting updating the favorite flag by book id.
 * @param {string} id Book id.
 * @param {boolean} isFavourite Favorite flag. 
 * @param {string} articleId Book article id.
 * @param {string} bookShelveId Bookshelf id.
 */

export const updateFavoriteBook = createAsyncThunk(
  "user/updateFavoriteBook",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const token = WebSession()?.access_token;
    try {
      const body = {
        bookId: data.id,
        isFavourite: data.favFlag,
        articleId: data.articleId,
        bookShelveId: data.bookShelveId,
      };
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post("api/book/favourite", body);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          updateFavorites(dispatch, body);
          return data.favFlag
            ? AppString.favorite.addFavorite
            : AppString.favorite.removeFavorite;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:updateFavoriteBookSlice
 * @method updateFavorites
 * @async
 * @description Method to update the rest of slice like deprecated, personal, paid bookshelf.
 * @param {Object} dispatch Redux dispatch
 * @param {Object} prop { bookId:string, isFavourite:boolean, bookShelveId:string }
 */

const updateFavorites = (dispatch, { bookId, isFavourite, bookShelveId }) => {
  if (bookShelveId === BookShelfId.FAVORITE_ID) {
    dispatch(updatePersonalBookForFavorite({ bookId, isFavourite }));
    dispatch(updateDeprecatedBookForFavorite({ bookId, isFavourite }));
    dispatch(updateBookForFavorite({ bookId, isFavourite }));
  } else if (bookShelveId === BookShelfId.PERSONAL_ID) {
    dispatch(updatePersonalBookForFavorite({ bookId, isFavourite }));
  } else if (bookShelveId === BookShelfId.DEPRECATED_ID) {
    dispatch(updateDeprecatedBookForFavorite({ bookId, isFavourite }));
  } else {
    dispatch(updateBookForFavorite({ bookId, isFavourite }));
  }
};

/**
 * @memberof slice:updateFavoriteBookSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const updateFavoriteBookSlice = createSlice({
  name: "updateFavoriteBook",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateFavoriteBook.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateFavoriteBook.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(updateFavoriteBook.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default updateFavoriteBookSlice.reducer;
