import { useAuth } from "oidc-react";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { AppTracker } from "../config";

/**
 * @category Hooks
 * @namespace useGtmEvent
 * @description This is to initialize and send gtm messages.
 * @property {Object} gtmEventData - Taken from redux 'gtmEvent' -> event, data
 * @property {string} userId
 */

export const useGtmEvent = () => {
  const gtmEventData = useSelector((state) => state.gtmEvent);
  const oidcData = useAuth();

  useEffect(() => {
    const { event, data } = gtmEventData;
    if (event === null || !oidcData?.userData) {
      return;
    }

    const email = oidcData.userData?.profile?.sub;
    if (
      email &&
      AppTracker.allowedEmailDomains.some(
        (domain) => domain && email.includes(domain)
      )
    ) {
      return;
    }

    const user_id = oidcData.userData?.profile?.userid;
    TagManager.dataLayer({
      dataLayer: {
        event,
        data,
        user_id,
      },
    });
  }, [gtmEventData, oidcData]);
};
