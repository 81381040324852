import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

/**
 * @category Routes
 * @subcategory customSnackBar
 * @namespace BookPaginatorAlert
 * @description Book pagination snack bar
 * @param {Object} message {id:int, severity:string, message:string}
 * @param {Function} removeSnackBar id
 * @class
 * @component
 */

const BookPaginatorAlert = ({ message, removeSnackBar }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  /**
   * @memberof BookPaginatorAlert
   * @method handleAlertClose
   * @description Closing the snack bar.
   * @property {Event} event Close event.
   * @property {string} reason Click reason.
   */
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    removeSnackBar(message.id);
  };

  /**
   * @memberof BookPaginatorAlert
   * @method onHandleChange
   * @description On icon click event.
   */
  const onHandleChange = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Alert
      onClose={handleAlertClose}
      elevation={6}
      variant="filled"
      severity={message.severity}
      iconMapping={{
        error: (
          <CircularProgress
            style={{ color: theme.palette.common.white }}
            size={20}
          />
        ),
        info: (
          <CircularProgress
            style={{ color: theme.palette.common.white }}
            size={20}
          />
        ),
      }}
      action={
        <IconButton color="inherit" size="small" onClick={onHandleChange}>
          {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
        </IconButton>
      }
    >
      {open ? message.message : ""}
    </Alert>
  );
};

BookPaginatorAlert.defaultProps = {
  message: {
    id: "",
    severity: "",
    message: "",
  },
  removeSnackBar: () => {},
  duration: 2000,
};

export default BookPaginatorAlert;
