import React from "react";
import { Outlet } from "react-router-dom";
import { useGtmEvent } from "../../hooks/useGtmEvent";
import GTMSetup from "../../routes/component/GTMSetup";

/**
 * @category Layouts
 * @namespace MinimalLayout
 * @description This is the minimal wrapper for the whole application. Here we place the gtm trigger.
 * @class
 * @component
 * return (
 *   <MinimalLayout />
 * )
 */

const MinimalLayout = () => {
    useGtmEvent();

    return (
      <>
        <GTMSetup />
        <Outlet />
      </>
    );
  };

export default MinimalLayout;
