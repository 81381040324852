import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import { setUserUniqueId } from "./saveUserUniqueIdSlice";
import { autoDecrementCount, clearCount } from "../reloaderSlice";
import { UserSession } from "../../../config/userManager";

/**
 * @category Redux
 * @subcategory userUniqueIdSlice
 * @namespace slice:updateUserUniqueIdSlice
 * @description updateUserUniqueIdSlice is slice for getting the user unique id.
 */

/**
 * @memberof slice:updateUserUniqueIdSlice
 * @method getUserUniqueId
 * @async
 * @description Rest api for getting the user unique id.
 * @param {Object} headerData {userUniqueId:string}.
 * @param {Object} bodyData null.
 * @param {string} accessToken Current accessToken.
 * @param {Object} param {type:string,userUniqueId:string}
 */

export const updateUserUniqueId = createAsyncThunk(
  "user/updateUserUniqueId",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const _data = {
      bodyData: null,
      headerData: {
        userUniqueId: UserSession.sessionOverride,
      },
      param: {
        type: "",
        userUniqueId: data?.userUniqueId,
      },
      accessToken: data.accessToken,
    };
    return await recursion(_data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const token = data?.accessToken
      ? data.accessToken
      : WebSession()?.access_token;
    const response = await myAxios(token, data.headerData.userUniqueId).put(
      "api/account/sessionInfo",
      {
        UserUniqueId: data.param.userUniqueId,
      }
    );
    if (response.status === 200) {
      if (response.data.status.statusCode === 200) {
        dispatch(clearCount("updateUserUniqueId"));
        const _data = response.data?.data?.[0];
        dispatch(
          setUserUniqueId({
            userUniqueId: _data?.userUniqueId,
            userId: _data?.userId,
            updatedTime: _data?.modifiedOn,
          })
        );
        return response.data;
      } else {
        return rejectWithValue(response.data.status.errorMessage);
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (getState().reloader["updateUserUniqueId"] !== 0) {
      dispatch(autoDecrementCount("updateUserUniqueId"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else {
      return rejectWithValue(err);
    }
  }
};

/**
 * @memberof slice:updateUserUniqueIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const updateUserUniqueIdSlice = createSlice({
  name: "updateUserUniqueId",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateUserUniqueId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserUniqueId.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.data = action.payload?.filter((val) => !val.articleId);
      }
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(updateUserUniqueId.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default updateUserUniqueIdSlice.reducer;
