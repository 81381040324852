import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory userUniqueIdSlice
 * @namespace slice:sessionPopupSlice
 * @description getUserUniqueIdSlice is slice for getting the user unique id.
 */

/**
 * @memberof slice:sessionPopupSlice
 * @name initialState
 * @description Initial slice state
 * @property {boolean} open Toggle session modal.
 * @property {boolean} refreshScreen Current screen while opening session modal.
 */
const initialState = {
  open: false,
  refreshScreen: false,
};

export const sessionPopupSlice = createSlice({
  name: "sessionPopup",
  initialState,
  reducers: {
    /**
     * @memberof slice:sessionPopupSlice
     * @method setSessionPopup
     * @description Toggle session modal.
     * @param {boolean} payload open.
     */
    setSessionPopup: (state, action) => {
      state.open = action.payload;
    },
    /**
     * @memberof slice:sessionPopupSlice
     * @method setRefreshScreenPopup
     * @description Current screen while opening session modal.
     * @param {Object} payload Refresh screen.
     */
    setRefreshScreenPopup: (state, action) => {
      state.refreshScreen = action.payload;
    },
    /**
     * @memberof slice:sessionPopupSlice
     * @method clearSessionPopup
     * @description Clear the sessionPopupSlice.
     */
    clearSessionPopup: (state, action) => {
      state.open = false;
      state.refreshScreen = false;
    },
  },
});

export const { setSessionPopup, clearSessionPopup, setRefreshScreenPopup } =
  sessionPopupSlice.actions;

export default sessionPopupSlice.reducer;
