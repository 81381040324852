import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import { setUserUniqueId } from "./saveUserUniqueIdSlice";
import { UserSession } from "../../../config/userManager";


/**
 * @category Redux
 * @subcategory userUniqueIdSlice
 * @namespace slice:getUserUniqueIdSlice
 * @description getUserUniqueIdSlice is slice for getting the user unique id.
 */

/**
 * @memberof slice:getUserUniqueIdSlice
 * @method getUserUniqueId
 * @async
 * @description Rest api for getting the user unique id.
 * @param {Object} headerData {userUniqueId:string}.
 * @param {Object} bodyData {userUniqueId:string}.
 * @param {string} accessToken Current accessToken.
 */

export const getUserUniqueId = createAsyncThunk(
  "user/getUserUniqueId",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = data?.accessToken
        ? data.accessToken
        : WebSession()?.access_token;
      const { bodyData, headerData } = data;
      const userUniqueId = bodyData?.userUniqueId ? bodyData?.userUniqueId : "";
      const _userUniqueId = headerData?.userUniqueId
        ? headerData?.userUniqueId
        : "";
      const response = await myAxios(
        token,
        _userUniqueId === UserSession.sessionOverride
          ? _userUniqueId
          : getState().saveUserUniqueId.userUniqueId
      ).get(`api/account/sessionInfo?userUniqueId=${userUniqueId}`);
      if (response.status === 200) {
        if (
          response.data.status.statusCode === 200 ||
          response.data.status.statusCode === 201
        ) {
          const _data = response.data?.data?.[0];
          dispatch(
            setUserUniqueId({
              userUniqueId: _data?.userUniqueId,
              userId: _data?.userId,
              updatedTime: _data?.modifiedOn,
            })
          );
          return response.data;
        } else {
          return response.data;
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

/**
 * @memberof slice:getUserUniqueIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getUserUniqueIdSlice = createSlice({
  name: "getUserUniqueId",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserUniqueId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getUserUniqueId.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.data = action.payload?.filter((val) => !val.articleId);
      }
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getUserUniqueId.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default getUserUniqueIdSlice.reducer;
