/**
 * @category UiComponents
 * @subcategory Common
 * @namespace Utiles
 * @description Utils fo the common folder
 */

/**
 * @memberof Utiles
 * @method IllegalChars
 * @description Function to remove illegal characters.
 * @param {string} text Text needed to manipulation.
 * @returns {string} Parsed text
 */
export const IllegalChars = (text) => {
  return /[%<>\[\]{}]/.test(text);
};

/**
 * @memberof Utiles
 * @method RemoveSpaces
 * @description Function to remove white spaces.
 * @param {string} text Text needed to manipulation.
 * @returns {string} Parsed text
 */
export const RemoveSpaces = (text) => {
  return text.replace(/^\s+/g, "").trim();
};
