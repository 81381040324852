import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory gtmEventSlice
 * @namespace slice:gtmEventSlice
 * @description gtmEventSlice is slice for setting the gtm trigger.
 */

/**
 * @memberof slice:gtmEventSlice
 * @name initialState
 * @description Initial slice state
 * @property {string} event Gtm event.
 * @property {Object} data {book:{id:string, articleId:string, title:string, type:string}}
 */
const initialState = {
  event: null,
  data: null,
};

export const gtmEventSlice = createSlice({
  name: "gtmEvent",
  initialState,
  reducers: {
    /**
     * @memberof slice:gtmEventSlice
     * @method setGtmEvent
     * @description Set the gtm trigger.
     * @param {object} payload {event:string, data:Object}
     */
    setGtmEvent: (state, action) => {
      const { event, data } = action.payload;
      state.event = event;
      state.data = data;
    },
  },
});

export const { setGtmEvent } = gtmEventSlice.actions;

export default gtmEventSlice.reducer;
