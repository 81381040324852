import { useAuth } from "oidc-react";
import React, { useEffect, memo, useCallback } from "react";
import Route from "./ThemeRoutes";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { removeSnackIndexById } from "../redux/slice/snackbarSlice";
import AppSpinner from "../ui-component/common/AppSpinner";
import Feedback from "../ui-component/feedback";
import { deleteUserUniqueId } from "../redux/slice/userUniqueIdSlice/deleteUserUniqueIdSlice";
import { clearLogout } from "../redux/slice/auth/logoutSlice";
import { UserSession } from "../config/userManager";
import LogoutPopup from "../ui-component/session/LogoutPopup";
import CustomSnackbar from "./customSnackBar";
import { URI } from "../config";
import { useBroadcastState } from "../hooks/reactBroadcastChannel";

const useStyles = makeStyles((theme) => ({
  superContainer: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    overflow: "hidden",
  },
  container: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    padding: 0,
    margin: 0,
  },
}));

/**
 * @category Routes
 * @namespace RouteWrapper
 * @description Route wrapper for whole application.
 * @property {React.FC} Route Application route.
 * @property {React.FC} CustomSnackbar Snackbar for the application.
 * @property {React.FC} Feedback User feedback modal.
 * @property {React.FC} AppSpinner Application loader.
 * @property {React.FC} LogoutPopup User logout modal.
 * @class
 * @component
 */

const EbokhyllanBroadcast = "EbokhyllanBroadcast";
const RouteWrapper = () => {
  const [broadcast, setBroadcast] = useBroadcastState(
    EbokhyllanBroadcast,
    false
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const logoutData = useSelector((state) => state.logout);
  const snackbarData = useSelector((state) => state.snackbar);
  const saveUserUniqueIdData = useSelector((state) => state.saveUserUniqueId);
  const deleteUserUniqueIdData = useSelector(
    (state) => state.deleteUserUniqueId
  );
  const bookResume = useSelector((state) => state.bookReader.bookResume);
  const oidcData = useAuth();

  useEffect(() => {
    onEffectHandle();
  }, [logoutData.isLogout]);

  /**
   * @memberof RouteWrapper
   * @method onEffectHandle
   * @async
   * @description If user click on logout (manual logout) then using useEffect this method gets triggered. Here user-unique-id is also deleted with user make manual logout.
   */

  const onEffectHandle = async () => {
    if (logoutData.isLogout) {
      if (saveUserUniqueIdData?.userUniqueId) {
        const data = {
          bodyData: null,
          headerData: {
            userUniqueId: UserSession.sessionOverride,
          },
          param: {
            userUniqueId: saveUserUniqueIdData?.userUniqueId,
            type: "",
          },
        };
        await dispatch(deleteUserUniqueId(data));
        dispatch(clearLogout());
      }
      window.localStorage.clear();
      window.sessionStorage.removeItem(URI.SESSION_KEY);
      await oidcData.signOutRedirect();
    }
  };

  /**
   * @memberof RouteWrapper
   * @method handleLogout
   * @async
   * @description After user logout navigating to logout screen with help of OIDC.
   */
  const handleLogout = async () => {
    dispatch(clearLogout());
    window.sessionStorage.removeItem(URI.SESSION_KEY);
    setBroadcast(true);
    await oidcData.signOutRedirect();
  };

  /**
   * @memberof RouteWrapper
   * @method removeSnackBar
   * @description  This is a callback function that removes snackbar with help of snackbar id.
   * @param {int|string} id Snackbar id.
   */
  const removeSnackBar = useCallback(
    (id) => {
      dispatch(removeSnackIndexById(id));
    },
    [dispatch, removeSnackIndexById]
  );

  useEffect(() => {
    if (broadcast) {
      handleLogout();
    }
  }, [broadcast]);

  return (
    <div className={classes.superContainer}>
      <Container className={classes.container} maxWidth="xl">
        <Route />
      </Container>
      <CustomSnackbar
        messages={snackbarData.snackPack}
        removeSnackBar={removeSnackBar}
        bookmarkMessageShow={bookResume}
      />
      <Feedback />
      <AppSpinner showLoader={deleteUserUniqueIdData.isLoading} />
      <LogoutPopup handleLogout={handleLogout} />
    </div>
  );
};

export default memo(RouteWrapper);
