import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";

/**
 * @category Routes
 * @namespace ThemeRoutes
 * @description Application mui themes.
 * @class
 * @component
 */

export default function ThemeRoutes() {
  return useRoutes([MainRoutes]);
}
