import { createTheme } from "@mui/material/styles";

// assets
import colors from "../assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * @category Themes
 * @namespace theme
 * @description mui index styles.
 * @property {JsonObject} customization customization parameter object
 */
export function theme() {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
  };

  return createTheme({
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "75px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "75px",
        },
      },
    },
    breakpoints: {
      keys: [
        "xs",
        "x100",
        "x200",
        "x400",
        "x500",
        "x600",
        "x675",
        "x750",
        "x800",
        "x880",
        "x960",
        "x1000",
        "x1060",
        "x1200",
        "x1100",
        "x1250",
        "x1300",
        "x1350",
        "x1400",
        "x1480",
        "x1600",
        "x1700",
        "x1800",
        "x1920",
        "x2000",
        "xl",
      ],
      values: {
        "xs":0,
        "x100":100,
        "x200":200,
        "x400":400,
        "x500":500,
        "x600": 600,
        "x675": 675,
        "x750":750,
        "x800": 800,
        "x880": 880,
        "x960":960,
        "x1000": 1000,
        "x1060":1060,
        "x1100": 1100,
        "x1200": 1200,
        "x1250":1250,
        "x1300":1300,
        "x1350":1350,
        "x1400":1400,
        "x1480":1480,
        "x1600":1600,
        "x1700":1700,
        "x1800": 1800,
        "x1920":1920,
        "x2000": 2000,
        "xl":9999
      },
      b1: "1060px",
    },
    typography: themeTypography(themeOption),
    components: componentStyleOverrides(themeOption),
    header: {
      height: 75,
      height2: 56,
      mobileHeight: 56,
    },
    footer: {
      height: 56,
    },
    strictParam: "12px",
    paddingHorizontal12: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    containerGridPadding: {
      paddingLeft: "5rem",
      paddingRight: "5rem",
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
    fontSize: {
      xs: 8,
      sm: 12,
      md: 16,
      lg: 20,
      xl: 24,
    },
    fontWeight: {
      xs: 100,
      sm: 250,
      md: 400,
      lg: 600,
      xl: 900,
    },
    containerConstraints: {
      leftAndRight: "5rem",
    },
    bookReaderIcon: {
      width: "25px",
      height: "25px",
    },
    empty: {
      bodyHeight: `calc(100vh - 2 * 80px)`,
      bodyHeightBrowserSupport: `calc(100vh - calc((2 * 80px) + 48px))`,
    },
    screen: {
      maxWidth: 1920,
    },
    dashboard: {
      cardMinWidth: 250,
      cardMaxWidth: "none",
      cardMinHeight: 407,
      cardMaxHeight: "none",
    },
    reader: {
      height: "90vh",
      width: "85vw",
      tocPanelWidth: 320,
      notebookPanelWidth: 400,
      searchPanelWidth: 400,
      transition: "0.2s",
      panelFullscreenHeight: "100vh",
      maxWidth: 1710,
    },
  });
}

export default theme;
