import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist';
import RootReducers from '../rootReducer';

/**
 * @category Redux
 * @subcategory store
 * @namespace store
 * @description Main store for the redux.
 */

/**
 * @memberof store
 * @name store
 * @description Main store for the redux.
 * @property {Object} reducer Pass the root reducer.
 * @property {Object} middleware Get default middleware with serializableCheck false.
 */
export const store = configureStore({
    reducer: RootReducers,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck:false,
    })
});

/**
 * @memberof store
 * @name persistor
 * @description Main variable for storage access.
 * @property {Object} store Pass the main store.
 */

export const persistor = persistStore(store);