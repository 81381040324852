import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory bookSortingSlice
 * @namespace slice:bookSortingSlice
 * @description bookSortingSlice is slice for sorting books in bookshelf.
 */

/**
 * @memberof slice:bookSortingSlice
 * @name Options
 * @description Sorting options example
 * @property {string} sortType default|custom
 * @property {string} title title-asc|title-desc
 * @property {string} year year-asc|year-desc
 * @property {string} sortType date-asc|date-desc
 * @property {string} popularity popularity-asc|popularity-desc
 */

// sortType: "default",
//   title: "title-asc",
//   year: "year-asc",
//   date: "date-asc",
//   popularity: "popularity-asc",

/**
 * @memberof slice:bookSortingSlice
 * @name initialState
 * @description Initial slice state
 * @property {Array<Object>} data {sortType:string, title:string, year:string, date:string, popularity:string}
 */

// Define the initial state using that type
const initialState = {
  data: [],
};

export const bookSortingSlice = createSlice({
  name: "bookSorting",
  initialState,
  reducers: {
    /**
     * @memberof slice:bookSortingSlice
     * @method setCurrentSort
     * @description Set the sorting option for bookshelf by shelf id.
     * @param {Object} payload {id:string, value:Object}
     */
    setCurrentSort: (state, action) => {
      const { id, value } = action.payload;
      const index = state.data.findIndex((ele) => ele.id === id);
      if (index !== -1) {
        state.data[index].value = value;
      } else {
        state.data = [
          ...state.data,
          {
            id,
            value: value ? value : "default",
          },
        ];
      }
    },
    /**
     * @memberof slice:bookSortingSlice
     * @method clearBookSorting
     * @description Clear the bookSortingSlice.
     */
    clearBookSorting: (state, action) => {
      state.data = [];
    },
  },
});

export const { setCurrentSort, clearBookSorting } = bookSortingSlice.actions;

export default bookSortingSlice.reducer;
