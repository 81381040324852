import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";

/**
 * @category Redux
 * @subcategory userUniqueIdSlice
 * @namespace slice:getAllUserUniqueIdSlice
 * @description getAllUserUniqueIdSlice is slice for getting all user unique ids.
 */

/**
 * @memberof slice:getAllUserUniqueIdSlice
 * @method getAllUserUniqueId
 * @async
 * @description Rest api for getting all user unique ids.
 */

export const getAllUserUniqueId = createAsyncThunk(
  "user/getAllUserUniqueId",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get("api/account/uniqueIdForUser");
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data?.data;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

/**
 * @memberof slice:getAllUserUniqueIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getAllUserUniqueIdSlice = createSlice({
  name: "getAllUserUniqueId",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllUserUniqueId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllUserUniqueId.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.data = action.payload?.filter((val) => !val.articleId);
      }
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getAllUserUniqueId.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default getAllUserUniqueIdSlice.reducer;
