import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { logout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { autoDecrementCount, clearCount } from "../reloaderSlice";
import { initialState } from "../../utils";

/**
 * @category Redux
 * @subcategory searchHistory
 * @namespace slice:searchHistorySlice
 * @description searchHistorySlice is slice for getting all user searched text.
 */
 
/**
 * @memberof slice:searchHistorySlice
 * @method searchHistory
 * @async
 * @description Rest api for getting all user searched text.
 */

export const searchHistory = createAsyncThunk(
  "user/searchHistory",
  async (data, { rejectWithValue, getState, dispatch }) => {
    return await recursion(data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const token = WebSession()?.access_token;
    const response = await myAxios(
      token,
      getState().saveUserUniqueId.userUniqueId
    ).get("api/search/text");
    if (response.status === 200) {
      dispatch(clearCount("searchHistory"));
      if (response.data.status.statusCode === 200) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.status.errorMessage);
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (err?.response?.status === 429) {
      dispatch(logout());
    } else if (getState().reloader["searchHistory"]) {
      dispatch(autoDecrementCount("searchHistory"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else {
      return rejectWithValue(err);
    }
  }
};

/**
 * @memberof slice:searchHistorySlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {boolean} isOpen Open search history popup
 * @property {string} searchText User searched text
 * @property {Array<Object>} filteredData Filtered search history result
 * @property {string} screenName Current screen name
 * @property {string} userSearchedText  Current user searched text
 * @property {string} suggestedText Suggested text
 */

export const searchHistorySlice = createSlice({
  name: "searchHistory",
  initialState: {
    ...initialState,
    isOpen: false,
    searchText: "",
    filteredData: [],
    screenName: "",
    userSearchedText: "",
    suggestedText: "",
  },
  extraReducers: (builder) => {
    builder.addCase(searchHistory.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(searchHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
    });

    builder.addCase(searchHistory.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:searchHistorySlice
     * @method setSuggestedText
     * @description Set the suggested text.
     * @param {Object} payload {suggestedText:string, userSearchedText:string, searchText:string}
     */
    setSuggestedText: (state, action) => {
      state.suggestedText = action.payload.suggestedText;
      state.userSearchedText = action.payload.userSearchedText;
      state.filteredData = state.data?.filter(
        (val) =>
          val.searchedText &&
          val.searchedText.includes(action.payload.searchText)
      );
    },
    /**
     * @memberof slice:searchHistorySlice
     * @method openSearchModal
     * @description Open the search model.
     * @param {Object} payload {screenName:string}
     */
    openSearchModal: (state, action) => {
      state.screenName = action.payload;
      state.isOpen = true;
    },
    /**
     * @memberof slice:searchHistorySlice
     * @method closeSearchModal
     * @description Close the search history modal.
     */
    closeSearchModal: (state, action) => {
      state.isOpen = false;
      state.screenName = "";
    },
    /**
     * @memberof slice:searchHistorySlice
     * @method searchModalTextClear
     * @description Clear the search text from the model input.
     */
    searchModalTextClear: (state, action) => {
      state.searchText = "";
    },
    /**
     * @memberof slice:searchHistorySlice
     * @method clearSearchHistory
     * @description Clear the search history.
     */
    clearSearchHistory: (state) => {
      state.data = null;
      state.filteredData = [];
    },
    /**
     * @memberof slice:searchHistorySlice
     * @method searchModalText
     * @description Set the search text only.
     * @param {string} payload Search text.
     */
    searchModalText: (state, action) => {
      state.searchText = action.payload;
      state.filteredData = state.data?.filter(
        (val) => val.searchedText && val.searchedText.includes(action.payload)
      );
    },
  },
});

export const {
  openSearchModal,
  closeSearchModal,
  searchModalTextList,
  searchModalTextClear,
  clearSearchHistory,
  setSuggestedText,
  searchModalText,
} = searchHistorySlice.actions;

export default searchHistorySlice.reducer;
