import { URI } from "../../config";

/**
 * @category Redux
 * @subcategory Utils
 * @namespace Redux:WebSessions
 * @description Fetch the access token from session storage.
 */

/**
 * @memberof Redux:WebSessions
 * @method WebSession
 * @description Fetch the access token from session storage.
 */

export const WebSession = () => {
  const mySession = window.sessionStorage.getItem(URI.SESSION_KEY);
  if (mySession) {
    return JSON.parse(mySession);
  }
  return null;
};
