import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory bookReaderSlice
 * @namespace slice:epubBookDataSlice
 * @description book data redux.
 */

/**
 * @memberof slice:epubBookDataSlice
 * @name initialState
 * @description Initial slice state
 * @property {string} bookType book type ebokhyllan/external
 * @property {string} data url.
 * @property {string} id Book Id.
 * @property {string} title Title of the book.
 * @property {string} fileType Type of book opfPath/epub
 */

const initialState = {
  bookType: "",
  data: "", //url, download data,
  id: "",
  title: "",
  fileType: "", //opfPath, epub
};

export const epubBookDataSlice = createSlice({
  name: "epubBookData",
  initialState,
  reducers: {
    /**
     * @memberof slice:epubBookDataSlice
     * @method setEpubBookData
     * @description Set the Epub book data
     * @param {Object} payload {id:string,title:string,bookType:string,data:string,fileType:string}
     */
    setEpubBookData: (state, action) => {
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.bookType = action.payload.bookType;
      state.data = action.payload.data;
      state.fileType = action.payload.fileType;
    },
    /**
     * @memberof slice:epubBookDataSlice
     * @method epubBookDataSlice
     * @description Clear the epubBookDataSlice.
     */
    clearEpubBookData: (state, action) => {
      state.id = "";
      state.bookType = "";
      state.title = "";
      state.data = "";
      state.fileType = "";
    },
  },
});

export const { setEpubBookData, clearEpubBookData } = epubBookDataSlice.actions;

export default epubBookDataSlice.reducer;
