import React, { useEffect } from "react";
import "./App.css";
import AppScreens from "./screens";
import { useLocation } from "react-router-dom";
import { setPath } from "./redux/slice/screenPathSlice";
import { useDispatch } from "react-redux";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./config/AppInsights";

/**
 * @category Root
 * @namespace App
 * @description This is the main starting view of the application.
 * @class
 * @component
 * @property {React.FC} AppScreens Application screens container.
 */

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      dispatch(setPath(`${location?.pathname}${location.search}`));
    }
  }, [location, dispatch]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppScreens />
    </AppInsightsContext.Provider>
  );
}
