import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { API_CONSTANTS, initialState } from "../../utils";
import { WebSession } from "../../utils/webSession";

/**
 * @category Redux
 * @subcategory searchHistory
 * @namespace slice:addSearchHistorySlice
 * @description addSearchHistorySlice is slice for posting new user search text.
 */

/**
 * @memberof slice:addSearchHistorySlice
 * @method addSearchHistory
 * @async
 * @description Rest api for posting new user search text.
 * @param {string} searchedText user searched text.
 */
export const addSearchHistory = createAsyncThunk(
  "user/addSearchHistory",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post("api/search/text", data);
      if (response.status === 200) {
        if (response.data.status.statusCode === 201) {
          return response.data.status.statusMessage;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:addSearchHistorySlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const addSearchHistorySlice = createSlice({
  name: "addSearchHistory",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addSearchHistory.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addSearchHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
    });

    builder.addCase(addSearchHistory.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default addSearchHistorySlice.reducer;
