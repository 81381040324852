import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState, API_CONSTANTS } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { autoDecrementCount, clearCount } from "../reloaderSlice";
import { setSuccessFavorites } from "../favoriteSlice/getAllFavoriteBooks";
import { setSuccessPersonalBooks } from "../personalBookShelfSlice";
import { setSuccessDeprecatedBooks } from "../deprecatedBooks/getDeprecatedBooksSlice";
import { setSuccessBookList } from "../bookShelfSlice/getAllBookList";

/**
 * @category Redux
 * @subcategory initialRequest
 * @namespace slice:getBookShelvesSlice
 * @description getBookShelvesSlice is slice for getting all the bookshelf. Favorite, personal, paid and deprecated.
 */

/**
 * @memberof slice:getBookShelvesSlice
 * @method getBookShelves
 * @async
 * @description Rest api for getting all the bookshelf.
 */

export const getBookShelves = createAsyncThunk(
  "books/getBookShelves",
  async (data, { rejectWithValue, getState, dispatch }) => {
    return await recursion(data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const token = WebSession()?.access_token;
    const response = await myAxios(
      token,
      getState().saveUserUniqueId.userUniqueId
    ).get("api/Bookshelf/all");
    if (response.status === 200) {
      dispatch(clearCount("getBookShelves"));
      if (response.data.status.statusCode === 200) {
        parseBookShelves(dispatch, response.data?.data);
        return "success";
      } else {
        return "fail";
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (err?.response?.status === 429) {
      dispatch(logout());
    } else if (getState().reloader["getBookShelves"]) {
      dispatch(autoDecrementCount("getBookShelves"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else if (err?.response?.status === 401) {
      dispatch(setSessionLogout(true));
      return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
    } else {
      return rejectWithValue(err);
    }
  }
};

/**
 * @memberof slice:getBookShelvesSlice
 * @method parseBookShelves
 * @description Method for parsing and dispatching bookshelf's to appropriate slice.
 * @param {Object} dispatch Redux dispatch.
 * @param {Object} data { favorite:Object, deprecated:Object, personal:Object, bookShelve:Object}.
 */

const parseBookShelves = (dispatch, data) => {
  const { favorite, deprecated, personal, bookShelve } = data;

  if (favorite && favorite.length > 0) {
    dispatch(setSuccessFavorites(data.favorite?.[0]));
  }
  if (deprecated && deprecated.length > 0) {
    dispatch(setSuccessDeprecatedBooks(data.deprecated?.[0]));
  }
  if (personal && personal.length > 0) {
    dispatch(setSuccessPersonalBooks(data.personal?.[0]));
  }
  if (bookShelve && bookShelve.length > 0) {
    dispatch(setSuccessBookList(data.bookShelve));
  }
};

/**
 * @memberof slice:getBookShelvesSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getBookShelvesSlice = createSlice({
  name: "getBookShelves",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getBookShelves.pending, (state, { meta }) => {
      state.isLoading = true;
    });

    builder.addCase(getBookShelves.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
      state.error = null;
    });

    builder.addCase(getBookShelves.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getBookShelvesSlice
     * @method clearGetBookShelvesSlice
     * @description Clear the getBookShelvesSlice.
     */
    clearGetBookShelvesSlice: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearGetBookShelvesSlice } = getBookShelvesSlice.actions;

export default getBookShelvesSlice.reducer;
