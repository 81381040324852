import React from "react";
import Stack from "@mui/material/Stack";
import { SNACK_CONSTANTS } from "../../redux/utils";
import CustomAlert from "./CustomAlert";
import BookPaginatorAlert from "./BookPaginatorAlert";
import BookResumeAlert from "./BookResumeAlert";
import { AppString } from "../../config";

/**
 * @category Routes
 * @subcategory customSnackBar
 * @namespace customSnackBar
 * @description Custom snackbar for the application.
 * @param {Array<Object>} messages {id:int, severity:string, message:string}
 * @param {function} removeSnackBar To move snackbar from snackPack
 * @param {boolean} bookmarkMessageShow Show the bookmark message.
 * 
 * @property {React.FC} CustomAlert Custom alert
 * @property {React.FC} BookPaginatorAlert Book pagination alert
 * @property {React.FC} BookResumeAlert Book resume alert
 * @class
 * @component
 */

export default function CustomSnackbar({
  messages,
  removeSnackBar,
  bookmarkMessageShow,
}) {
  return (
    <Stack
      sx={{ position: "fixed", bottom: 24, right: 24, zIndex: 2 }}
      spacing={2}
    >
      {messages.map((message) => (
        <>
          {message.id !== SNACK_CONSTANTS.BOOK_PAGINATING ? (
            <CustomAlert message={message} removeSnackBar={removeSnackBar} />
          ) : (
            <BookPaginatorAlert
              message={message}
              removeSnackBar={removeSnackBar}
            />
          )}
        </>
      ))}
      {bookmarkMessageShow ? (
        <BookResumeAlert
          message={{ severity: "warning", message: AppString.bookResume.title }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}

CustomSnackbar.defaultProps = {
  messages: [],
  removeSnackBar: () => {},
};
