import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";

/**
 * @category Redux
 * @subcategory bookmark
 * @namespace slice:getAllBookmarksSlice
 * @description getAllBookmarksSlice is slice for getting all bookmarks.
 */

/**
 * @memberof slice:getAllBookmarksSlice
 * @method getAllBookmarks
 * @async
 * @description Rest api to get all bookmarks.
 */

export const getAllBookmarks = createAsyncThunk(
  "book/getAllBookmarks",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get("api/bookmark");
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data.data;
        }
      }
      return [];
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getAllBookmarksSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getAllBookmarksSlice = createSlice({
  name: "getAllBookmarks",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllBookmarks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllBookmarks.fulfilled, (state, action) => {
      state.data = action.payload;
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getAllBookmarks.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getAllBookmarksSlice
     * @method getAllBookmarksReload
     * @description Reload flag for get api.
     */
    getAllBookmarksReload: (state) => {
      state.hasData = false;
    },
    /**
     * @memberof slice:getAllBookmarksSlice
     * @method setSuccessGetAllBookmark
     * @description Get api success response .
     * @property {Object} payload
     */
    setSuccessGetAllBookmark: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getAllBookmarksSlice
     * @method setErrorGetAllBookmark
     * @description Get api error response .
     * @property {string} payload
     */
    setErrorGetAllBookmark: (state, action) => {
      state.hasData = false;
      state.hasError = true;
      state.error = action.payload;
      state.data = null;
      state.isLoading = false;
    },
  },
});

export const {
  getAllBookmarksReload,
  setSuccessGetAllBookmark,
  setErrorGetAllBookmark,
} = getAllBookmarksSlice.actions;

export default getAllBookmarksSlice.reducer;
