import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

/**
 * @category Config 
 * @namespace reactPlugin
 * @description This is to initiate microsoft insights for the application.
 */

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});
appInsights.loadAppInsights();

export { reactPlugin };