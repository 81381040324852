import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

//redux
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./redux/store";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

/**
 * @ignore
 * @category Root
 * @namespace Index
 * @description This is the index part of the application.
 * @class
 * @component
 * @property {React.FC} Provider Redux provider for the store.
 * @property {React.FC} PersistGate Redux persist gate for storage.
 * @property {React.FC} BrowserRouter React-route-dom browser router.
 * @property {React.FC} App Main view of the application.
 */

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <Provider {...{ store }}>
      <PersistGate {...{ persistor }} loading={null}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
