import React, { useEffect, memo } from "react";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

//oidc
import { AuthProvider } from "oidc-react";
import { URI, userManager } from "../config";

// routing
import RouteWrapper from "../routes";

// defaultTheme
import themes from "../themes";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slice/auth/logoutSlice";
import { setAppVersion } from "../redux/slice/auth/applicationVersionSlice";
import { clearCompanyInfo } from "../redux/slice/auth/companyInfoSlice";

/**
 * @category Screens
 * @namespace AppScreens
 * @description This is the home or dashboard screen for the application.
 * @class
 * @component
 * @property {React.FC} RouteWrapper Route wrapper for the application.
 */

function AppScreens() {
  const dispatch = useDispatch();

  const applicationVersion = useSelector((state) => state.applicationVersion);

  useEffect(() => {
    if (
      applicationVersion.appVersion !== URI.VERSION ||
      localStorage.getItem("token")
    ) {
      clearCache();
    }
  }, []);

  /**
   * @memberof AppScreens
   * @method onBeforeSignIn
   * @description What should application do before user signIn. Here we clear company info.
   */
  const onBeforeSignIn = () => {
    /* commented this line since cleaning storage before login doesnt make sense. 
    If anything goes wrong uncomment it */
    // window.localStorage.clear();

    dispatch(clearCompanyInfo());
  };

  /**
   * @memberof AppScreens
   * @method clearCache
   * @async
   * @description Clearing the cache data like local and session storage.
   */
  const clearCache = async () => {
    await dispatch(setAppVersion(URI.VERSION));
    window.localStorage.clear();
    window.sessionStorage.clear();

    //cookies
    try {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    } catch (e) {}
    emptyCache();
    await dispatch(setAppVersion(URI.VERSION));
    dispatch(logout());
  };

  const emptyCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names?.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  };

  const onSignIn = async (user) => {};

  const onSignOut = () => {
    //should trigger when user logout
  };

  return (
    <AuthProvider
      {...userManager}
      onBeforeSignIn={onBeforeSignIn}
      onSignIn={onSignIn}
      onSignOut={onSignOut}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes()}>
          <CssBaseline />
          <RouteWrapper />
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthProvider>
  );
}

export default memo(AppScreens);
