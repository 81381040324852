import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState, API_CONSTANTS } from "../../utils";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import axios from "axios";
import { URI } from "../../../config";
import {
  setErrorGetAllHighlights,
  setSuccessGetAllHighlights,
} from "../highlight/getAllHighlights";
import {
  setErrorGetAllBookmark,
  setSuccessGetAllBookmark,
} from "../bookmark/getAllBookmarks";
import {
  setErrorGetAllNotes,
  setSuccessGetAllNotes,
} from "../notes/getAllNotes";

/**
 * @category Redux
 * @subcategory initialRequest
 * @namespace slice:getNotebookSlice
 * @description getNotebookSlice is slice for getting all user notes by book id.
 */

/**
 * @memberof slice:getNotebookSlice
 * @method getNotebook
 * @async
 * @description Rest api for getting all user notes by book id.
 */

export const getNotebook = createAsyncThunk(
  "book/getNotebook",
  async (data, { rejectWithValue, getState, dispatch, signal }) => {
    try {
      const token = WebSession()?.access_token;
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`api/notes/all`, {
        baseURL: URI.BASE_URL,
        headers: {
          Authorization: "Bearer " + token,
          "user-unique-id": getState().saveUserUniqueId.userUniqueId,
        },
        cancelToken: source.token,
      });
      signal.removeEventListener("abort", null);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          parseNotebook(dispatch, response.data?.data);
          return "success";
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      signal.removeEventListener("abort", null);
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getNotebookSlice
 * @method parseNotebook
 * @description Method for parsing and dispatching user notes to appropriate slice.
 * @param {Object} dispatch Redux dispatch.
 * @param {Object} data { highlights:Object, notes:Object, bookmarks:Object}.
 */

const parseNotebook = (dispatch, data) => {
  const { highlights, notes, bookmarks } = data;
  if (highlights && highlights.length > 0) {
    dispatch(setSuccessGetAllHighlights(highlights));
  } else {
    dispatch(setErrorGetAllHighlights());
  }
  if (notes && notes.length > 0) {
    dispatch(setSuccessGetAllNotes(notes));
  } else {
    dispatch(setErrorGetAllNotes());
  }
  if (bookmarks && bookmarks.length > 0) {
    dispatch(setSuccessGetAllBookmark(bookmarks));
  } else {
    dispatch(setErrorGetAllBookmark());
  }
};

/**
 * @memberof slice:getNotebookSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getNotebookSlice = createSlice({
  name: "getNotebook",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotebook.pending, (state, { meta }) => {
      state.isLoading = true;
    });

    builder.addCase(getNotebook.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
      state.error = null;
    });

    builder.addCase(getNotebook.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getNotebookSlice
     * @method clearGetNotebookSlice
     * @description Clear the getNotebookSlice.
     */
    clearGetNotebookSlice: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
    /**
     * @memberof slice:getNotebookSlice
     * @method getAllNotebookReload
     * @description Get api reload flag.
     */
    getAllNotebookReload: (state) => {
      state.hasData = false;
    },
  },
});

export const { getAllNotebookReload, clearGetNotebookSlice } =
  getNotebookSlice.actions;

export default getNotebookSlice.reducer;
