import { createSlice } from "@reduxjs/toolkit";
import { SNACK_CONSTANTS } from "../../utils";

/**
 * @category Redux
 * @subcategory snackbarSlice
 * @namespace slice:snackbarSlice
 * @description snackbarSlice is slice for posting new user search text.
 */

/**
 * @memberof slice:snackbarSlice
 * @name initialState
 * @description Initial slice state
 * @property {boolean} show Toggle the snackbar.
 * @property {string} type Type of snackbar.
 * @property {string} message Snackbar message.
 * @property {Array<Object>} snackPack Snackbar pending pack.
 */
const initialState = {
  show: false,
  type: "",
  message: "",
  snackPack: [],
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    /**
     * @memberof slice:autoCompleteSlice
     * @method setSnackbar
     * @description Add the snackbar info to snackPack
     * @param {Object} payload {id: int, message:string, type:string}
     */
    setSnackbar: (state, action) => {
      const { id } = action.payload;
      state.show = true;

      if (state.snackPack.length > SNACK_CONSTANTS.MAX_SNACK) {
        const value = state.snackPack.find(
          (val) => val.id !== SNACK_CONSTANTS.BOOK_PAGINATING
        );
        if (value) {
          state.snackPack = state.snackPack.filter(
            (val) => val.id !== value.id
          );
        }
      }
      const isPaginatedView = state.snackPack.find(
        (val) => val.id === SNACK_CONSTANTS.BOOK_PAGINATING
      );
      if (id === SNACK_CONSTANTS.BOOK_PAGINATING && isPaginatedView) {
        return;
      }
      state.snackPack.push({
        message: action.payload.message,
        id: action.payload?.id ? action.payload?.id : new Date().getTime(),
        severity: action.payload.type,
      });
    },
    /**
     * @memberof slice:autoCompleteSlice
     * @method removeSnackIndexById
     * @description Remove snackbar from snackPack using id.
     * @param {int} payload Snackbar id.
     */
    removeSnackIndexById: (state, action) => {
      state.snackPack = state.snackPack.filter(
        (val) => val.id !== action.payload
      );
    },
    /**
     * @memberof slice:autoCompleteSlice
     * @method removeSnackIndex
     * @description Auto remove snackbar by position.
     */
    removeSnackIndex: (state, action) => {
      if (state.snackPack.length > 1) {
        state.snackPack.shift();
        state.show = true;
      } else {
        state.snackPack = [];
        state.show = false;
      }
    },
    /**
     * @memberof slice:autoCompleteSlice
     * @method clearSnackbar
     * @description Clear the whole snackbarPack and hide snackbar.
     */
    clearSnackbar: (state, action) => {
      state.show = false;
      state.snackPack = [];
    },
  },
});

export const {
  setSnackbar,
  clearSnackbar,
  removeSnackIndex,
  removeSnackIndexById,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
