import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory feedbackSlice
 * @namespace slice:feedbackModalSlice
 * @description feedbackModalSlice is slice for handling the feedback modal.
 */

/**
 * @memberof slice:feedbackModalSlice
 * @name initialState
 * @description Initial slice state
 * @property {boolean} isOpen Toggle feedback modal.
 * @property {boolean} isSuccess Feedback response success flag.
 */
const initialState = {
  isOpen: false,
  isSuccess: false,
};

export const feedbackModalSlice = createSlice({
  name: "feedbackModal",
  initialState,
  reducers: {
    /**
     * @memberof slice:feedbackModalSlice
     * @method setFeedbackModal
     * @description Toggle feedback modal.
     * @param {boolean} payload
     */
    setFeedbackModal: (state, action) => {
      state.isOpen = action.payload;
    },
    /**
     * @memberof slice:feedbackModalSlice
     * @method setSuccessFavorites
     * @description Feedback response success flag.
     * @param {boolean} payload
     */
    setFeedbackStatus: (state, action) => {
      state.isSuccess = action.payload;
    },
    /**
     * @memberof slice:feedbackModalSlice
     * @method setSuccessFavorites
     * @description Clear the feedbackModalSlice.
     */
    clearFeedbackModal: (state, action) => {
      state.isOpen = false;
      state.isSuccess = false;
    },
  },
});

export const { setFeedbackModal, setFeedbackStatus, clearFeedbackModal } =
  feedbackModalSlice.actions;

export default feedbackModalSlice.reducer;
