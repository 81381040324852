import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory highlight
 * @namespace slice:addMultiHighlightsSlice
 * @description addMultiHighlightsSlice is slice for posting new book highlights.
 */

/**
 * @memberof slice:addMultiHighlightsSlice
 * @method addMultiHighlights
 * @async
 * @description Rest api for posting new book listed highlights.
 * @param {List<Object>} data List of highlights. {bookId:string, cfi:string, chapterName:string, color:string, text:string}.
 */

export const addMultiHighlights = createAsyncThunk(
  "book/addMultiHighlights",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      let addHighlightArray = [];
      let response = null;
      for (let i = 0; i < data.length; i++) {
        response = await myAxios(
          token,
          getState().saveUserUniqueId.userUniqueId
        ).post(`api/highlight`, data[i]);
        if (response.status !== 200) break;
        addHighlightArray.push({ cfi: data[i].cfi, color: data[i].color });
      }
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return addHighlightArray;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:addMultiHighlightsSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const addMultiHighlightsSlice = createSlice({
  name: "addMultiHighlights",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addMultiHighlights.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addMultiHighlights.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(addMultiHighlights.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:addMultiHighlightsSlice
     * @method clearAddMultiHighlights
     * @description Clear the addMultiHighlightsSlice.
     */
    clearAddMultiHighlights: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearAddMultiHighlights } = addMultiHighlightsSlice.actions;

export default addMultiHighlightsSlice.reducer;
