import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory reloaderSlice
 * @namespace slice:reloaderSlice
 * @description reloaderSlice is slice for handle api error's.
 */

const RELOAD_COUNT = 3;

/**
 * @memberof slice:reloaderSlice
 * @name initialState
 * @description Initial slice state
 * @property {int} companyInfo Reloader for companyInfo slice.
 * @property {int} getAllFavoriteBooks Reloader for getAllFavoriteBooks slice.
 * @property {int} getAllBookList Reloader for getAllBookList slice.
 * @property {int} searchHistory Reloader for searchHistory slice.
 * @property {int} getAllPersonalBooks Reloader for getAllPersonalBooks slice.
 * @property {int} updateUserUniqueId Reloader for updateUserUniqueId slice.
 * @property {int} getBookShelves Reloader for getBookShelves slice.
 */

const initialState = {
  companyInfo: RELOAD_COUNT,
  getAllFavoriteBooks: RELOAD_COUNT,
  getAllBookList: RELOAD_COUNT,
  searchHistory: RELOAD_COUNT,
  getAllPersonalBooks: RELOAD_COUNT,
  updateUserUniqueId: RELOAD_COUNT,
  getBookShelves: RELOAD_COUNT,
};

export const reloaderSlice = createSlice({
  name: "reloader",
  initialState,
  reducers: {
    /**
     * @memberof slice:reloaderSlice
     * @method autoDecrementCount
     * @description Auto decrement the reloader count.
     * @param {string} payload InitialState key
     */
    autoDecrementCount: (state, action) => {
      state[action.payload] = state[action.payload] - 1;
    },
    /**
     * @memberof slice:reloaderSlice
     * @method clearCount
     * @description Clear the reloader count.
     */
    clearCount: (state, action) => {
      state[action.payload] = RELOAD_COUNT;
    },
  },
});

export const { autoDecrementCount, clearCount } = reloaderSlice.actions;

export default reloaderSlice.reducer;
