import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { API_CONSTANTS, initialState } from "../../utils";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory notes
 * @namespace slice:deleteNotesByIdSlice
 * @description deleteNotesByIdSlice is slice for deleting notes.
 */

/**
 * @memberof slice:deleteNotesByIdSlice
 * @method deleteNotesById
 * @async
 * @description Rest api for deleting notes.
 * @param {int} id Notes id.
 * @param {string} cfi Book cfi.
 */

export const deleteNotesById = createAsyncThunk(
  "book/deleteNotesById",
  async ({ id, cfi }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).delete(`api/notes/${id}`);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return {
            cfi,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:deleteNotesByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {boolean} showPopup Toggle notes popup menu.
 * @property {string} bookId Book id.
 * @property {string} id Notes id.
 * @property {string} cfi Book cfi.
 */

export const deleteNotesByIdSlice = createSlice({
  name: "deleteNotesById",
  initialState: {
    ...initialState,
    showPopup: false,
    bookId: "",
    id: "",
    cfi: "",
  },
  extraReducers: (builder) => {
    builder.addCase(deleteNotesById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteNotesById.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(deleteNotesById.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:deleteNotesByIdSlice
     * @method showPopup
     * @description Show notes delete popup menu.
     * @param {Object} payload {bookId:string, id:int, cfi:string}
     */
    showPopup: (state, action) => {
      state.showPopup = true;
      state.bookId = action.payload.bookId;
      state.id = action.payload.id;
      state.cfi = action.payload.cfi;
    },
    /**
     * @memberof slice:deleteNotesByIdSlice
     * @method clearDeleteNotesById
     * @description Clear the deleteNotesByIdSlice.
     */
    clearDeleteNotesById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.showPopup = false;
      state.bookId = "";
      state.id = "";
      state.cfi = "";
    },
  },
});

export const { clearDeleteNotesById, showPopup } = deleteNotesByIdSlice.actions;

export default deleteNotesByIdSlice.reducer;
