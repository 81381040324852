import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";

/**
 * @category Redux
 * @subcategory highlight
 * @namespace slice:getAllHighlightsSlice
 * @description getAllHighlightsSlice is slice for getting all the highlights.
 */

/**
 * @memberof slice:getAllHighlightsSlice
 * @method getAllHighlights
 * @async
 * @description Rest api for getting all the highlights.
 */

export const getAllHighlights = createAsyncThunk(
  "book/getAllHighlights",
  async (spliceValue, { rejectWithValue, getState, dispatch }) => {
    try {
      // const token = getState().userToken.data.access_token;
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get("api/highlight");
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data.data;
        }
      }
      return [];
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getAllHighlightsSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getAllHighlightsSlice = createSlice({
  name: "getAllHighlights",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllHighlights.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllHighlights.fulfilled, (state, action) => {
      state.data = action.payload;
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });
    builder.addCase(getAllHighlights.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getAllHighlightsSlice
     * @method getAllHighlightsReload
     * @description Reset highlights reload flag.
     */
    getAllHighlightsReload: (state) => {
      state.hasData = false;
    },
    /**
     * @memberof slice:getAllHighlightsSlice
     * @method setSuccessGetAllHighlights
     * @description Success response.
     * @param {Object} payload Highlight data.
     */
    setSuccessGetAllHighlights: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getAllHighlightsSlice
     * @method setErrorGetAllHighlights
     * @description Error response.
     * @param {string} payload Error data
     */
    setErrorGetAllHighlights: (state, action) => {
      state.hasData = false;
      state.hasError = true;
      state.error = action.payload;
      state.data = null;
      state.isLoading = false;
    },
  },
});

export const {
  getAllHighlightsReload,
  setSuccessGetAllHighlights,
  setErrorGetAllHighlights,
} = getAllHighlightsSlice.actions;

export default getAllHighlightsSlice.reducer;
