import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setBookNotFound } from ".";
import { URI } from "../../../config";
import { API_CONSTANTS, initialState } from "../../utils";
import { WebSession } from "../../utils/webSession";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { setEpubBookData } from "./epubBookData";

/**
 * @category Redux
 * @subcategory bookReaderSlice
 * @namespace slice:getBookByIdSlice
 * @description getBookByIdSlice is slice for getting book by id.
 */

/**
 * @memberof slice:getBookByIdSlice
 * @method getBookById
 * @async
 * @description Rest api to getting book by id.
 * @param {string} bookId Book id.
 *
 */

export const getBookById = createAsyncThunk(
  "book/getBookById",
  async ({ bookId }, { rejectWithValue, getState, dispatch, signal }) => {
    try {
      const token = WebSession()?.access_token;
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`api/book/${bookId}`, {
        baseURL: URI.BASE_URL,
        headers: {
          Authorization: "Bearer " + token,
          "user-unique-id": getState().saveUserUniqueId.userUniqueId,
        },
        cancelToken: source.token,
      });
      signal.removeEventListener("abort", null);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return {
            bookId: response.data.data.id,
            data: response.data.data,
            cacheFlag: getState().getBookById.cacheFlag,
          };
        } else if (response.data.status.statusCode === 400) {
          dispatch(setBookNotFound(true));
          dispatch(
            setEpubBookData({
              id: null,
              title: "",
              bookType: null,
              data: null,
              fileType: "epub",
            })
          );
          return rejectWithValue(response.data.status.errorMessage);
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      signal.removeEventListener("abort", null);

      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(
          err?.response?.status ? err?.response?.status : err?.message
        );
      }
    }
  }
);

/**
 * @memberof slice:getBookByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {Array<Object>} cachedData Cache the book result with book id.
 * @property {string} bookType Book type.
 * @property {boolean} cacheFlag Is book cached flag.
 */

export const getBookByIdSlice = createSlice({
  name: "getBookById",
  initialState: {
    ...initialState,
    cachedData: [],
    bookType: "",
    cacheFlag: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getBookById.pending, (state, { meta }) => {
      const { bookId } = meta.arg;
      const cachedData = state.cachedData?.find((val) => val.bookId === bookId);
      if (cachedData) {
        state.data = cachedData?.data;
        state.hasData = true;
        state.hasError = false;
        state.error = null;
        state.bookType = cachedData?.data?.bookType;
        state.cacheFlag = true;
      } else {
        state.isLoading = true;
        state.cacheFlag = false;
        state.hasError = false;
        state.error = null;
      }
    });

    builder.addCase(getBookById.fulfilled, (state, action) => {
      const { bookId, data } = action.payload;
      state.hasData = true;
      state.data = data;
      state.hasError = false;
      state.isLoading = false;
      state.bookType = data?.bookType;
      // state.cacheFlag = false;
      const index = state.cachedData?.findIndex((val) => val.bookId === bookId);
      if (data?.opfPath) {
        if (index === -1) {
          state.cachedData.push({
            bookId,
            data,
          });
        } else {
          state.cachedData[index] = { bookId, data };
        }
      }
    });

    builder.addCase(getBookById.rejected, (state, action) => {
      state.hasData = false;
      state.error = action;
      state.hasError = true;
      state.isLoading = false;
      state.cacheFlag = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getBookByIdSlice
     * @method removeCachedBook
     * @description Remove cached book by book id.
     * @param {string} payload Book id.
     */
    removeCachedBook: (state, action) => {
      if (state.cachedData.length > 0) {
        const index = state.cachedData?.findIndex(
          (val) => val.bookId === action.payload
        );
        if (index !== -1) {
          state.cachedData.splice(index, 1);
        }
      }
    },
    /**
     * @memberof slice:getBookByIdSlice
     * @method setBookMeta
     * @description Set book meta data.
     * @param {Object} payload {book:Object, bookId:string, articleId:string}
     */
    setBookMeta: (state, action) => {
      const { book, bookId, articleId } = action.payload;
      state.data = {
        ...state.data,
        ...book,
      };
      const index1 = state.cachedData?.findIndex(
        (val) => val.bookId === bookId
      );
      const index2 = state.cachedData?.findIndex(
        (val) => val.bookId === articleId
      );
      if (book?.opfPath) {
        if (index1 === -1 && index2 === -1) {
          state.cachedData.push({
            bookId,
            data: state.data,
          });
        } else {
          state.cachedData[index1 !== -1 ? index1 : index2] = {
            bookId: index1 !== -1 ? bookId : articleId,
            data: state.data,
          };
        }
      }
    },
    /**
     * @memberof slice:getBookByIdSlice
     * @method clearGetBookCachedData
     * @description Clear all cached books.
     */
    clearGetBookCachedData: (state, action) => {
      state.cachedData = [];
    },
    /**
     * @memberof slice:getBookByIdSlice
     * @method clearGetBookById
     * @description Clear the getBookByIdSlice excluding the cache.
     */
    clearGetBookById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.bookType = "";
      state.cacheFlag = false;
    },
    /**
     * @memberof slice:getBookByIdSlice
     * @method clearAllGetBookById
     * @description Clear the getBookByIdSlice.
     */
    clearAllGetBookById: (state) => {
      state.cachedData = [];
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
      state.bookType = "";
      state.cacheFlag = false;
    },
  },
});

export const {
  clearGetBookById,
  clearGetBookCachedData,
  removeCachedBook,
  clearAllGetBookById,
  setBookMeta,
} = getBookByIdSlice.actions;

export default getBookByIdSlice.reducer;
