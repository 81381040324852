import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { autoDecrementCount, clearCount } from "../reloaderSlice";
import { BookShelfId } from "../../../config";

/**
 * @category Redux
 * @subcategory personalBookShelfSlice
 * @namespace slice:personalBookShelfSlice
 * @description personalBookShelfSlice is slice for getting personal bookshelf.
 */

/**
 * @memberof slice:personalBookShelfSlice
 * @method getPersonalShelf
 * @async
 * @description Rest api for getting personal bookshelf.
 *
 */

export const getPersonalShelf = createAsyncThunk(
  "user/getPersonalShelf",
  async (data, { rejectWithValue, getState, dispatch }) => {
    return await recursion(data, rejectWithValue, getState, dispatch);
  }
);

const recursion = async (data, rejectWithValue, getState, dispatch) => {
  try {
    const token = WebSession()?.access_token;
    const response = await myAxios(
      token,
      getState().saveUserUniqueId.userUniqueId
    ).get(`api/bookshelf/${BookShelfId.PERSONAL_ID}`);
    if (response.status === 200) {
      dispatch(clearCount("getPersonalShelf"));
      if (response.data.status.statusCode === 200) {
        return response.data?.data ? response.data.data : [];
      } else {
        return rejectWithValue(response.data.status.errorMessage);
      }
    } else {
      return rejectWithValue("error on api");
    }
  } catch (err) {
    if (err?.response?.status === 429) {
      dispatch(logout());
    } else if (getState().reloader["getPersonalShelf"]) {
      dispatch(autoDecrementCount("getPersonalShelf"));
      return recursion(data, rejectWithValue, getState, dispatch);
    } else {
      return rejectWithValue(err);
    }
  }
};

/**
 * @memberof slice:personalBookShelfSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getPersonalShelfSlice = createSlice({
  name: "getPersonalShelf",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPersonalShelf.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPersonalShelf.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getPersonalShelf.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:personalBookShelfSlice
     * @method updatePersonalBookForFavorite
     * @description Update the favorite flag for personal books.
     * @param {object} payload {bookId:string, isFavourite:boolean}
     */
    updatePersonalBookForFavorite: (state, action) => {
      const { bookId, isFavourite } = action.payload;
      state.data?.books?.map((v) => {
        if (v.id === bookId) {
          v.isFavourite = isFavourite;
        }
        return v;
      });
    },
    /**
     * @memberof slice:personalBookShelfSlice
     * @method setSuccessPersonalBooks
     * @description Success response.
     * @param {object} payload Personal bookshelf data
     */
    setSuccessPersonalBooks: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:personalBookShelfSlice
     * @method setSuccessPersonalSortedBooks
     * @description Setting the sorted personal books.
     */
    setSuccessPersonalSortedBooks: (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:personalBookShelfSlice
     * @method setPersonalBookDetails
     * @description This method is to add book details grid to existing personal bookshelf.
     * @param {object} payload {bookId:string, bookshelfId:string, numberOfGridPerRow:int}
     */
    setPersonalBookDetails: (state, action) => {
      const { bookshelfId, bookId, numberOfGridPerRow } = action.payload;
      const _book = state.data.books.find((x) => x.id === bookId);
      state.data.books = state.data.books.filter((x) => !x?.isClicked);
      const _index = state.data.books.findIndex((x) => x.id === bookId);
      const _gridIndex =
        (Math.floor(_index / numberOfGridPerRow) + 1) * numberOfGridPerRow;
      state.data = {
        ...state.data,
        clickId: `${bookshelfId}-${bookId}`,
      };
      state.data.books.splice(_gridIndex, 0, {
        ..._book,
        isClicked: true,
      });
    },
    /**
     * @memberof slice:personalBookShelfSlice
     * @method resetPersonalBookDetails
     * @description Close or reset the book details grid in personal bookshelf.
     */
    resetPersonalBookDetails: (state, action) => {
      try {
        state.data.books = state.data?.books?.filter((x) => !x?.isClicked);
        state.data = {
          ...state.data,
          clickId: "",
        };
      } catch (ex) {}
    },
  },
});

export const {
  setSuccessPersonalBooks,
  setSuccessPersonalSortedBooks,
  updatePersonalBookForFavorite,
  setPersonalBookDetails,
  resetPersonalBookDetails,
} = getPersonalShelfSlice.actions;

export default getPersonalShelfSlice.reducer;
