import * as React from "react";

/**
 * React hook to create and manage a Broadcast Channel across multiple browser windows.
 *
 * @param channelName Static name of channel used across the browser windows.
 * @param handleMessage Callback to handle the event generated when `message` is received.
 * @param handleMessageError [optional] Callback to handle the event generated when `error` is received.
 * @returns A function to send/post message on the channel.
 * @example
 * ```jsx
 * import { useBroadcastChannel } from 'react-broadcast-channel';
 *
 * function App () {
 *   const postUserIdMessage = useBroadcastChannel('userId', (e) => alert(e.data));
 *   return (<button onClick={() => postUserIdMessage('ABC123')}>Send UserId</button>);
 * }
 * ```
 */
export function useBroadcastChannel(
  channelName,
  handleMessage,
  handleMessageError
) {
  const [channel] = React.useState(
    typeof window !== "undefined" && "BroadcastChannel" in window
      ? new BroadcastChannel(channelName + "-channel")
      : null
  );

  useChannelEventListener(channel, "message", handleMessage);
  useChannelEventListener(channel, "messageerror", handleMessageError);

  return React.useCallback((data) => channel?.postMessage(data), [channel]);
}

/**
 * React hook to manage state across browser windows. Has the similar signature as `React.useState`.
 *
 * @param channelName Static name of channel used across the browser windows.
 * @param initialState Initial state.
 * @returns Tuple of state and setter for the state.
 * @example
 * ```jsx
 * import { useBroadcastState } from 'react-broadcast-channel';
 *
 * function App () {
 *   const [count, setCount] = useBroadcastState('count', 0);
 *   return (
 *     <div>
 *       <button onClick={() => setCount(prev => prev - 1)}>Decrement</button>
 *       <span>{count}</span>
 *       <button onClick={() => setCount(prev => prev + 1)}>Increment</button>
 *     </div>
 *   );
 * }
 * ```
 */
export function useBroadcastState(channelName, initialState) {
  const [isPending, startTransition] = React.useTransition();
  const [state, setState] = React.useState(initialState);
  const broadcast = useBroadcastChannel(channelName, (ev) => setState(ev.data));

  const updateState = React.useCallback(
    (input) => {
      setState((prev) => {
        const newState = typeof input === "function" ? input(prev) : input;
        startTransition(() => broadcast(newState));
        return newState;
      });
    },
    [broadcast]
  );

  return [state, updateState, isPending];
}

// Helpers

/** Hook to subscribe/unsubscribe from channel events. */
function useChannelEventListener(channel, event, handler) {
  const callbackRef = React.useRef(handler);
  if (callbackRef.current !== handler) {
    callbackRef.current = handler;
  }

  React.useEffect(() => {
    const callback = callbackRef.current;
    if (!channel || !callback) {
      return;
    }

    channel.addEventListener(event, callback);
    return () => channel.removeEventListener(event, callback);
  }, [channel, event]);
}
