import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { BookShelfId } from "../../../config";

/**
 * @category Redux
 * @subcategory deprecatedBooks
 * @namespace slice:getDeprecatedBooksSlice
 * @description getDeprecatedBooksSlice is slice for getting deprecated bookshelf.
 */

/**
 * @memberof slice:getDeprecatedBooksSlice
 * @method getDeprecatedBooks
 * @async
 * @description Rest api to getting deprecated bookshelf.
 *
 */

export const getDeprecatedBooks = createAsyncThunk(
  "book/getDeprecatedBooks",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get(`api/bookshelf/${BookShelfId.DEPRECATED_ID}`);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data?.data ? response.data.data : [];
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getDeprecatedBooksSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getDeprecatedBooksSlice = createSlice({
  name: "getDeprecatedBooks",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDeprecatedBooks.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.hasData = false;
    });

    builder.addCase(getDeprecatedBooks.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getDeprecatedBooks.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getDeprecatedBooksSlice
     * @method updateDeprecatedBookForFavorite
     * @description Update the favorite flag for deprecated books.
     * @param {object} payload {bookId:string, isFavourite:boolean}
     */
    updateDeprecatedBookForFavorite: (state, action) => {
      const { bookId, isFavourite } = action.payload;
      state.data?.books?.map((v) => {
        if (v.id === bookId) {
          v.isFavourite = isFavourite;
        }
        return v;
      });
    },
    /**
     * @memberof slice:getDeprecatedBooksSlice
     * @method clearGetDeprecatedBooksSlice
     * @description Clear the getDeprecatedBooksSlice.
     */
    clearGetDeprecatedBooksSlice: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
    /**
     * @memberof slice:getDeprecatedBooksSlice
     * @method setSuccessDeprecatedBooks
     * @description Success response.
     * @param {object} payload bookshelf data
     */
    setSuccessDeprecatedBooks: (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
      state.isLoading = false;
    },
    /**
     * @memberof slice:getDeprecatedBooksSlice
     * @method setDeprecatedBookDetails
     * @description this method is to add book details grid to existing deprecated bookshelf.
     * @param {object} payload {bookId:string, bookshelfId:string, numberOfGridPerRow:int}
     */
    setDeprecatedBookDetails: (state, action) => {
      const { bookshelfId, bookId, numberOfGridPerRow } = action.payload;
      const _book = state.data.books.find((x) => x.id === bookId);
      state.data.books = state.data.books.filter((x) => !x?.isClicked);
      const _index = state.data.books.findIndex((x) => x.id === bookId);
      const _gridIndex =
        (Math.floor(_index / numberOfGridPerRow) + 1) * numberOfGridPerRow;
      state.data = {
        ...state.data,
        clickId: `${bookshelfId}-${bookId}`,
      };
      state.data.books.splice(_gridIndex, 0, {
        ..._book,
        isClicked: true,
      });
    },
    /**
     * @memberof slice:getDeprecatedBooksSlice
     * @method resetDeprecatedBookDetails
     * @description Close or reset the book details grid in deprecated bookshelf.
     */
    resetDeprecatedBookDetails: (state, action) => {
      try {
        state.data.books = state.data?.books?.filter((x) => !x?.isClicked);
        state.data = {
          ...state.data,
          clickId: "",
        };
      } catch (ex) {}
    },
  },
});

export const {
  clearGetDeprecatedBooksSlice,
  setSuccessDeprecatedBooks,
  updateDeprecatedBookForFavorite,
  setDeprecatedBookDetails,
  resetDeprecatedBookDetails,
} = getDeprecatedBooksSlice.actions;

export default getDeprecatedBooksSlice.reducer;
