import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory highlight
 * @namespace slice:deleteHighlightsByIdSlice
 * @description deleteHighlightsByIdSlice is slice for deleting book highlight.
 */

/**
 * @memberof slice:deleteHighlightsByIdSlice
 * @method deleteHighlightsById
 * @async
 * @description Rest api for deleting book highlight.
 * @param {int} id Highlight id.
 * @param {string} cfi Highlight cfi.
 */

export const deleteHighlightsById = createAsyncThunk(
  "book/deleteHighlightsById",
  async ({id, cfi}, { rejectWithValue, getState, dispatch }) => {
    try {
      // const token = getState().userToken.data.access_token;
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).delete(`api/highlight/${id}`);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return {
            cfi
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:deleteHighlightsByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const deleteHighlightsByIdSlice = createSlice({
  name: "deleteHighlightsById",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteHighlightsById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteHighlightsById.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(deleteHighlightsById.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
        /**
     * @memberof slice:deleteHighlightsByIdSlice
     * @method clearDeleteHighlightsById
     * @description Clear the deleteHighlightsByIdSlice.
     */
    clearDeleteHighlightsById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearDeleteHighlightsById } = deleteHighlightsByIdSlice.actions;

export default deleteHighlightsByIdSlice.reducer;
