import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { setFeedbackStatus } from "./feedbackModalSlice";
import { AppString, FeedbackEmails } from "../../../config";

/**
 * @category Redux
 * @subcategory feedbackSlice
 * @namespace slice:feedbackSlice
 * @description feedbackSlice is slice for getting favorite bookshelf.
 */

/**
 * @memberof slice:feedbackSlice
 * @method myFormData
 * @description Rest api to getting favorite bookshelf.
 * @property {string} text Feedback text.
 * @property {Object=} file Feedback screenshot.
 *
 * @returns {FormData} Appended with text, toMails and screenshot.
 */

const myFormData = (data) => {
  const formData = new FormData();
  formData.append("subject", AppString.feedback.email.subject);
  formData.append("content", data.text);
  FeedbackEmails.map((val, index) => {
    formData.append(`to[${index}]`, val);
    return val;
  });
  if (data.file) {
    formData.append("attachments", data.file);
  }
  return formData;
};

/**
 * @memberof slice:feedbackSlice
 * @method feedback
 * @async
 * @description Rest api to posting user feedback.
 * @property {string} text Feedback text.
 * @property {Object=} file Feedback screenshot.
 */

export const feedback = createAsyncThunk(
  "user/feedback",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post(`api/service/email`, myFormData(data));
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(setFeedbackStatus(true));
          return response.data?.data;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:feedbackSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(feedback.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.hasData = false;
    });

    builder.addCase(feedback.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(feedback.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:feedbackSlice
     * @method clearFeedback
     * @description Clear the feedbackSlice.
     */
    clearFeedback: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
