import React, { useEffect, useRef } from "react";
import Alert from "@mui/material/Alert";
import { makeStyles, useTheme } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useDispatch, useSelector } from "react-redux";
import { closeBookResume } from "../../redux/slice/bookReaderSlice";
import { setGotoPage } from "../../redux/slice/bookReaderSlice/bookDataSlice";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import { NotebookType } from "../../config";

const useStyles = makeStyles((theme) => ({
  alertAction: {
    padding: 0,
    paddingLeft: theme.strictParam,
  },
  muiRoot: {
    backgroundColor: theme.palette.common.orange,
    color: theme.palette.common.white,
    cursor: "pointer",
  },
}));

/**
 * @category Routes
 * @subcategory customSnackBar
 * @namespace BookResumeAlert
 * @description Book resume snackbar.
 * @param {Object} message {id:int, severity:string, message:string}
 * @param {int} duration Duration in seconds.
 * @class
 * @component
 */

const BookResumeAlert = ({ message, duration }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const bookResumeLoc = useSelector((state) => state.bookReader.bookResumeLoc);
  const ref = useRef(null);

  useEffect(() => {
    if (bookResumeLoc) {
      ref.current = setTimeout(function () {
        onCancelClick();
      }, duration);
    }
    return () => {
      clearTimer();
    };
  }, [bookResumeLoc]);

  /**
   * @memberof BookResumeAlert
   * @method handleAlertClose
   * @description Closing the snack bar.
   * @property {Event} event Close event.
   * @property {string} reason Click reason.
   */
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onCancelClick();
  };

  /**
   * @memberof BookResumeAlert
   * @method clearTimer
   * @description Clear the timeout using reference.
   */
  const clearTimer = () => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
  };

  /**
   * @memberof BookResumeAlert
   * @method onBookmarkClick
   * @description Book resume icon click event for bookmark.
   */
  const onBookmarkClick = () => {
    dispatch(
      setGotoPage({
        location: bookResumeLoc,
        type: NotebookType.bookmarks,
      })
    );
    onCancelClick();
  };

  /**
   * @memberof BookResumeAlert
   * @method handleAlertClose
   * @description Book resume icon click event for cancel.
   */
  const onCancelClick = (e) => {
    e?.stopPropagation();
    clearTimer();
    dispatch(closeBookResume());
  };

  return (
    <Alert
      onClose={handleAlertClose}
      elevation={6}
      variant="outline"
      severity={message.severity}
      classes={{
        action: classes.alertAction,
        root: classes.muiRoot,
      }}
      iconMapping={{
        warning: (
          <AssistantDirectionIcon
            style={{ color: theme.palette.common.white }}
            size={20}
          />
        ),
      }}
      onClick={onBookmarkClick}
      action={
        <div>
          <IconButton color="inherit" size="small">
            <BookmarkIcon />
          </IconButton>
          <IconButton color="inherit" size="small" onClick={onCancelClick}>
            <CloseIcon />
          </IconButton>
        </div>
      }
    >
      {message.message}
    </Alert>
  );
};

BookResumeAlert.defaultProps = {
  message: {
    id: "",
    severity: "",
    message: "",
  },
  duration: 10000,
};

export default BookResumeAlert;
