import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { API_CONSTANTS, initialState } from "../../utils";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory notes
 * @namespace slice:createNotesSlice
 * @description createNotesSlice is slice for posting new notes.
 */

/**
 * @memberof slice:createNotesSlice
 * @method createNotes
 * @async
 * @description Rest api for posting new notes.
 * @param {string} bookId Book id for the notes.
 * @param {string} cfi Book cfi id for the notes.
 * @param {string} chapterName Book chapterName for the notes.
 * @param {string} color color for the notes.
 * @param {string} text Book text for the notes.
 */

export const createNotes = createAsyncThunk(
  "book/createNotes",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post(`api/notes`, data);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return {
            cfi: data.cfi,
            color: data.color,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:createNotesSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {boolean} showPopup Toggle notes popup menu.
 * @property {string} bookId Book id.
 */

export const createNotesSlice = createSlice({
  name: "createNotes",
  initialState: {
    ...initialState,
    showPopup: false,
    bookId: "",
  },
  extraReducers: (builder) => {
    builder.addCase(createNotes.pending, (state) => {
      state.isLoading = true;
      state.hasData = false;
      state.hasError = false;
    });

    builder.addCase(createNotes.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(createNotes.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:createNotesSlice
     * @method showPopup
     * @description Show notes editor popup menu.
     * @param {string} payload book id.
     */
    showPopup: (state, action) => {
      state.showPopup = true;
      state.bookId = action.payload;
    },
    /**
     * @memberof slice:createNotesSlice
     * @method clearPopup
     * @description Clear notes editor popup menu.
     */
    clearPopup: (state, action) => {
      state.showPopup = false;
      state.bookId = "";
    },
    /**
     * @memberof slice:createNotesSlice
     * @method clearCreateNotes
     * @description Clear the createNotesSlice.
     */
    clearCreateNotes: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearCreateNotes, showPopup, clearPopup } =
  createNotesSlice.actions;

export default createNotesSlice.reducer;
