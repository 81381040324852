/**
 * @category Redux
 * @subcategory Utils
 * @namespace Redux:Utils
 * @description Utility file for redux.
 */

/**
 * @memberof Redux:Utils
 * @typedef {Object} initialStateParam
 * @property {boolean} isLoading Used when result is in pending.
 * @property {boolean} hasData Used when result is in success.
 * @property {boolean} hasError Used when result is in fail.
 * @property {null} data Used for success response.
 * @property {null} error Used for fail response.
 * @description Default initial state for redux slice.
 */
export const initialState = {
  isLoading: false,
  hasData: false,
  hasError: false,
  data: null,
  error: null,
};

/**
 * @memberof Redux:Utils
 * @typedef {Object} ApiConstantsParam
 * @property {string} NO_DATA No data available
 * @property {string} SESSION_EXPIRED Sessionen löpte ut.
 * @property {string} NETWORK_ERROR Network Error.
 * @description Default api return constants.
 */
export const API_CONSTANTS = {
  NO_DATA: "No data available",
  SESSION_EXPIRED: "Sessionen löpte ut",
  NETWORK_ERROR:"Network Error"

};

/**
 * @memberof Redux:Utils
 * @typedef {Object} SnackConstantParam
 * @property {string} INFO Snack info type.
 * @property {string} SUCCESS Snack success type.
 * @property {string} WARNING Snack warning type.
 * @property {string} INFINITY Keep snack for infinite time.
 * @property {string} BOOK_READER Snack for book reader.
 * @property {string} BOOK_PAGINATING Snack for book pagination.
 * @property {int} MAX_SNACK Maximum number of snackBars.
 * @description Default snack constants.
 */
export const SNACK_CONSTANTS = {
  INFO:"info",
  SUCCESS:"success",
  WARNING:"warning",

  INFINITY:"infinity",

  BOOK_READER:"bookReader",
  BOOK_PAGINATING:"bookPaginating",

  MAX_SNACK : 1
}

/**
 * @memberof Redux:Utils
 * @method queryConverter
 * @property {string} data Data is Type = default/custom.
 * @description This is used for sorting books.
 */

export const queryConverter = (data) => {
  if (data === "default" || !data) {
    return `?type=default`;
  } else {
    let query = `?type=custom`;
    const _data = data.split("-")
    query += `&${_data[0]}=${_data[1]}`;
    return query;
  }
}


