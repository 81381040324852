import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState, API_CONSTANTS } from "../../utils";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import axios from "axios";
import { URI } from "../../../config";
import {
  setErrorGetHighlightByBookId,
  setSuccessGetHighlightByBookId,
} from "../highlight/getHighlightById";
import {
  setErrorGetNoteByBookId,
  setSuccessGetNoteByBookId,
} from "../notes/getNotesById";
import {
  setErrorGetBookmarkByBookId,
  setSuccessGetBookmarkByBookId,
} from "../bookmark/getBookmarkById";

/**
 * @category Redux
 * @subcategory initialRequest
 * @namespace slice:getNotebookByIdSlice
 * @description getNotebookByIdSlice is slice for getting all user notes.
 */

/**
 * @memberof slice:getNotebookByIdSlice
 * @method getNotebookById
 * @async
 * @description Rest api for getting all user notes.
 * @param {string} bookId Book id.
 */

export const getNotebookById = createAsyncThunk(
  "book/getNotebookById",
  async (
    { bookId, isInitial },
    { rejectWithValue, getState, dispatch, signal }
  ) => {
    try {
      const token = WebSession()?.access_token;
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`api/notes/all/${bookId}`, {
        baseURL: URI.BASE_URL,
        headers: {
          Authorization: "Bearer " + token,
          "user-unique-id": getState().saveUserUniqueId.userUniqueId,
        },
        cancelToken: source.token,
      });
      signal.removeEventListener("abort", null);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          parseNotebook(dispatch, response.data.data);
          return "success";
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      signal.removeEventListener("abort", null);
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getNotebookByIdSlice
 * @method parseNotebook
 * @description Method for parsing and dispatching user notes to appropriate slice.
 * @param {Object} dispatch Redux dispatch.
 * @param {Object} data { highlights:Object, notes:Object, bookmarks:Object} .
 */

const parseNotebook = (dispatch, data) => {
  const { highlights, notes, bookmarks } = data;
  if (highlights && highlights?.length > 0) {
    dispatch(setSuccessGetHighlightByBookId(highlights));
  } else {
    dispatch(setErrorGetHighlightByBookId());
  }
  if (notes && notes?.length > 0) {
    dispatch(setSuccessGetNoteByBookId(notes));
  } else {
    dispatch(setErrorGetNoteByBookId());
  }
  if (bookmarks && bookmarks?.length > 0) {
    dispatch(setSuccessGetBookmarkByBookId(bookmarks));
  } else {
    dispatch(setErrorGetBookmarkByBookId());
  }
};

/**
 * @memberof slice:getNotebookByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getNotebookByIdSlice = createSlice({
  name: "getNotebookById",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotebookById.pending, (state, { meta }) => {
      state.isLoading = true;
    });

    builder.addCase(getNotebookById.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.hasData = true;
      state.data = data;
      state.hasError = false;
      state.isLoading = false;
      state.error = null;
    });

    builder.addCase(getNotebookById.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getNotebookByIdSlice
     * @method clearGetNotebookByIdSlice
     * @description Clear the getNotebookByIdSlice.
     */
    clearGetNotebookByIdSlice: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearGetNotebookByIdSlice } = getNotebookByIdSlice.actions;

export default getNotebookByIdSlice.reducer;
