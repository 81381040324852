import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory screenPathSlice
 * @namespace slice:screenPathSlice
 * @description screenPathSlice is slice for handle api error's.
 */

/**
 * @memberof slice:screenPathSlice
 * @name initialState
 * @description Initial slice state
 * @property {Array<string>} path List of url paths.
 * @property {string} currentPath Current url path.
 */

const initialState = {
  path: [],
  currentPath: "",
};

export const screenPathSlice = createSlice({
  name: "screenPath",
  initialState,
  reducers: {
    /**
     * @memberof slice:screenPathSlice
     * @method setPath
     * @description Clear the reloader count.
     * @param {string} payload Url path.
     */
    setPath: (state, action) => {
      state.path.unshift(action.payload);
      if (state.path.length > 2) {
        state.path.pop();
      }
    },
    /**
     * @memberof slice:screenPathSlice
     * @method setCurrentPath
     * @description Clear the reloader count.
     * @param {string} payload Current url path.
     */
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
    },
    /**
     * @memberof slice:screenPathSlice
     * @method clearCount
     * @description Clear the url path.
     */
    clearItem: (state, action) => {
      state.path = [];
    },
  },
});

export const { setPath, clearItem, setCurrentPath } = screenPathSlice.actions;

export default screenPathSlice.reducer;
