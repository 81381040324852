import { clearAutoComplete } from "../../redux/slice/searchHistory/autoCompleteSlice";
import { searchModalText } from "../../redux/slice/searchHistory/searchHistory";
import { store } from "../../redux/store";

/**
 * @category UiComponents
 * @subcategory Search
 * @namespace SearchUtils
 * @description Search utils for used in search page.
 */

/**
 * @memberof SearchUtils
 * @method getParsedText
 * @description Get only text and remove html content
 * @param {string} text Search result text with dom element.
 * @returns {string} Plain text.
 */
export const getParsedText = (text) => {
  if (!text) return "";
  var tmp = document.createElement("DIV");
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText || "";
};

/**
 * @memberof SearchUtils
 * @method onHighlightText
 * @description Get only text and remove html content
 * @param {string} text Search result text.
 * @param {int} textLength Maximum number of text characters.
 * @param {string} searchText User searched text.
 * @returns {string} Required search text.
 */
export const onHighlightText = (text, textLength, searchText) => {
  try {
    if (searchText && text) {
      const _text = text.toLowerCase();
      const _searchedText = searchText?.toLowerCase();
      const _textLength = textLength;
      const breaker = _text.split(_searchedText);
      const leftArray = breaker?.[0]?.split("");

      const rightArray = breaker?.[1]?.split("");
      const firstBreaker =
        _textLength > leftArray.length ? 0 : leftArray.length - _textLength;
      const finalLeftArray = leftArray?.slice(firstBreaker, leftArray.length);
      const finalRightArray = rightArray?.slice().splice(0, _textLength);
      let finalText = "";

      finalText = `${
        finalLeftArray ? finalLeftArray?.join("") : ""
      }${_searchedText}${finalRightArray ? finalRightArray?.join("") : ""}`;

      return finalText;
    }
    return text;
  } catch (e) {
    return text;
  }
};

/**
 * @memberof SearchUtils
 * @method getValidExcerpt
 * @description Get only text and remove html content
 * @param {string} text Search result excerpt.
 * @returns {string} Required search text.
 */
export const getValidExcerpt = (text) => {
  if (!text) {
    return text;
  }

  const split = text.split(".");
  if (split.length === 0) {
    return text;
  }
  const filter = split.filter((val) => val.length > 2 && val.includes("<span"));
  if (filter.length === 0) {
    const _filter = split.filter((val) => val.length > 2);
    return _filter?.[0] ? _filter[0] : text;
  }
  return getParsedText(filter?.[0]);
};

/**
 * @memberof SearchUtils
 * @method searchSuggestText
 * @description To check whether to use user text or suggestion text.
 * @param {string} suggest Search suggestion result.
 * @param {string} t1 Search result text.
 * @param {string} defaultValue User search text.
 * @returns {string} Required search text.
 */
export const searchSuggestText = (suggest, t1, defaultValue) => {
  const _defaultValue =
    typeof defaultValue === "string" ||
    typeof defaultValue === "number" ||
    typeof defaultValue === "boolean"
      ? defaultValue
      : null;
  return suggest !== "null" && suggest ? suggest : t1 ? t1 : _defaultValue;
};

/**
 * @memberof SearchUtils
 * @method searchModalTextHandler
 * @description To clear auto complete results if less than 3.
 * @param {string} suggest user input text.
 */
export const searchModalTextHandler = (text) => {
  const dispatch = store.dispatch;
  try {
    if (text.length < 3) {
      dispatch(clearAutoComplete());
    }
    dispatch(searchModalText(text));
  } catch (ex) {
    return "";
  }
};

/**
 * @memberof SearchUtils
 * @method capitalizeWords
 * @description This is to replace and cap size the text
 * @param {string} str String to capital
 * @returns {string} Capital text.
 */
export const capitalizeWords = (str) => {
  return str.replace(/(^|\s)\S/g, function (match) {
    return match.toUpperCase();
  });
}
