import React, { lazy } from "react";
import Loadable from "../ui-component/Loadable";

// project imports
import MinimalLayout from "../layout/MinimalLayout";
import ScreenContainer from "../screens/utils/ScreenContainer";

// routing
const AuthScreen = Loadable(lazy(() => import("../screens/AuthScreen")));
const CallbackScreen = Loadable(
  lazy(() => import("../screens/CallbackScreen"))
);
const SilentScreen = Loadable(lazy(() => import("../screens/SilentScreen")));
const Dashboard = Loadable(lazy(() => import("../screens/Dashboard")));
const Notebook = Loadable(lazy(() => import("../screens/NoteBook")));
const BookReader = Loadable(lazy(() => import("../screens/BookReader")));
const BookSearch = Loadable(lazy(() => import("../screens/BookSearch")));

/**
 * @category Routes
 * @namespace MainRoutes
 * @description User routes after authentication.
 * @param {React.FC} AuthScreen Path is '/'.
 * @param {React.FC} Dashboard Path is '/dashboard'.
 * @param {React.FC} CallbackScreen Path is '/callback'.
 * @param {React.FC} SilentScreen Path is '/silent'.
 * @param {React.FC} Notebook Path is '/notebook/:id'.
 * @param {React.FC} BookReader Path is '/reader/:id'.
 * @param {React.FC} BookReader Path is '/reader/:articleId'.
 * @param {React.FC} BookSearch Path is '/search'.
 * @class
 * @component
 */

const MainRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthScreen />,
      exact: true,
    },
    {
      path: "/dashboard",
      element: (
        <ScreenContainer>
          <Dashboard />
        </ScreenContainer>
      ),
    },
    {
      path: "/callback",
      element: <CallbackScreen />,
    },
    {
      path: "/silent",
      element: <SilentScreen />,
    },
    {
      path: "/notebook/:id",
      element: (
        <ScreenContainer>
          <Notebook />
        </ScreenContainer>
      ),
    },
    {
      path: "/reader/:id", //  /reader/articleId?orderId=123
      element: (
        <ScreenContainer>
          <BookReader />
        </ScreenContainer>
      ),
    },
    {
      path: "/search",
      element: (
        <ScreenContainer>
          <BookSearch />
        </ScreenContainer>
      ),
    },
  ],
};

export default MainRoutes;
