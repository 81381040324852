import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Brand from "../../assets/icons/logo-login.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  RenderTextBody,
  RenderHeading,
  RenderTextField,
  RenderScreenShot,
  RenderFeedbackFooter,
  RenderFeedbackSuccess,
} from "./FeedbackComponent";
import { AppString, ExternalLinks } from "../../config";
import AppSpinner from "../common/AppSpinner";
import { feedback } from "../../redux/slice/feedbackSlice";
import { clearFeedbackModal } from "../../redux/slice/feedbackSlice/feedbackModalSlice";
import { setSnackbar } from "../../redux/slice/snackbarSlice";
import { IllegalChars } from "../common/utiles";
import { API_CONSTANTS } from "../../redux/utils";

const useStyles = makeStyles((theme) => ({
  feedbackContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: 300,
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    padding: 22,
    outline: "none",
    maxWidth: 500,
  },
  successPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {},
  inputField: {
    fontSize: 16,
  },
  fileInputTitle: {},
  ssName: {
    padding: "10px 0 6px 0 ",
    margin: "10px 0 6px 0 ",
    fontSize: 16,
  },
  fileName: {
    marginLeft: 8,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre",
    [`@media (max-width:${theme.breakpoints.b1})`]: {
      width: 230,
    },
  },
  fileInputField: {
    display: "none",
  },
  fileInputWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
  },
  fileInputButton: {
    whiteSpace: "nowrap",
    height: "fit-content",
    fontFamily: "inherit",
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "8px 12px",
    cursor: "pointer",
    borderRadius: 6,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "#e0e0e0",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
  feedbackFooterContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fileSubmitBtn: {
    textTransform: "none",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  fileCancelBtn: {
    marginLeft: 12,
    textTransform: "none",
  },
  errorText: {
    fontSize: 14,
    color: "red",
  },
  sizeDisclaimerContainer: {
    backgroundColor: "#008746 ",
    display: "flex",
    alignItems: "center",
  },
  sizeDisclaimerIcon: {
    color: "#e0e0e0",
    marginLeft: 12,
  },
  sizeDisclaimerText: {
    fontSize: 14,
    color: "#e0e0e0",
    margin: "10px",
  },
  fileSizeError: {
    fontSize: 14,
    color: theme.palette.common.closeRed,
    marginTop: "10px",
  },
  successTextTitle: {
    textAlign: "center",
    marginBottom: 0,
  },
  successText: {
    fontSize: 18,
    width: "60%",
    textAlign: "center",
    lineHeight: 1.5,
    marginTop: 4,
  },
  successCheck: {
    color: "green",
    fontSize: 80,
    marginTop: 12,
  },
  successBtn: {
    margin: "10px 0",
    width: "50%",
    textTransform: "none",
  },
  feedbackTitle: {
    fontSize: 18,
    marginBottom: 18,
  },
  feedbackText: {
    fontSize: 14,
    margin: 0,
  },
  feedbackTextSpan: {
    fontSize: 14,
  },
  feedbackTextATag: {
    fontSize: 14,
    color: "blue",
    cursor: "pointer",
  },
}));

/**
 * @category UiComponents
 * @subcategory Feedback
 * @namespace Feedback
 * @description Feedback main component.
 * @class
 * @component
 * @property {React.FC} RenderHeading Feedback header component. 
 * @property {React.FC} RenderTextBody Feedback text body component. 
 * @property {React.FC} RenderTextField Feedback user text field. 
 * @property {React.FC} RenderScreenShot Feedback screenshot component. 
 * @property {React.FC} RenderFeedbackFooter Feedback footer component. 
 * @property {React.FC} RenderFeedbackSuccess Feedback success component. 
 */
const Feedback = () => {
  const classes = useStyles();
  const reportFunctionData = useSelector((state) => state.feedback);
  const feedbackModalData = useSelector((state) => state.feedbackModal);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    filename: "",
    file: null,
    text: "",
    error: "",
    fileSizeError: "",
  });

  const clearText = () => {
    setState({
      filename: "",
      file: null,
      text: "",
      error: "",
      fileSizeError: "",
    });
  };

  /**
   * @memberof Feedback
   * @method onSelectedText
   * @description Function is triggered when model is closed.
   */
  const handleClose = () => {
    clearText();

    dispatch(clearFeedbackModal());
  };

  /**
   * @memberof Feedback
   * @method onHandleText
   * @description User input text change handler
   * @param {Event} event Input event
   */
  const onHandleText = (event) => {
    const { value } = event.target;
    setState({
      ...state,
      text: value,
      error: "",
    });
  };

  /**
   * @memberof Feedback
   * @method onSubmit
   * @description Function is triggered when submit button is clicked.
   */
  const onSubmit = () => {
    if (!state.text) {
      setState({
        ...state,
        error: AppString.feedback.emptyData,
      });
    } else if (IllegalChars(state.text)) {
      setState({
        ...state,
        error: AppString.illegalChars,
      });
    } else {
      sendUserFeedback();
    }
  };

  /**
   * @memberof Feedback
   * @method sendUserFeedback
   * @description Function is extension of onSubmit method.
   */
  const sendUserFeedback = async () => {
    const response = await dispatch(feedback(state));
    if (response.meta.requestStatus === "rejected") {
      dispatch(
        setSnackbar({
          type: "error",
          message:
            response.payload === API_CONSTANTS.NETWORK_ERROR
              ? AppString.NETWORK_ERROR
              : AppString.feedback.failed,
        })
      );
    } else {
    }
  };

  /**
   * @memberof Feedback
   * @method onFileHandle
   * @async
   * @description Function is used when user has to add screenshot for feedback
   * @param {Event} event Input file event
   */
  const onFileHandle = async (event) => {
    const { files } = event.target;
    if (files?.length > 0) {
      const file = files[0];
      const size = file.size / (1000 * 1024);
      if (size > 20) {
        setState({
          ...state,
          fileSizeError: AppString.feedback.fileSizeError,
        });
      } else {
        const rawImage = URL.createObjectURL(file);
        const validImage = await onImageLoad(rawImage);
        if (!validImage) {
          setState({
            ...state,
            filename: "",
            file: null,
            fileSizeError: AppString.feedback.validImage,
          });
        } else {
          setState({
            ...state,
            file: files[0],
            filename: files[0].name,
            fileSizeError: "",
            error: "",
          });
        }
        try {
          URL.revokeObjectURL(rawImage);
        } catch (e) {}
      }
    }
  };

  /**
   * @memberof Feedback
   * @method onImageLoad
   * @description Function is to check if image is valid image.
   * @param {string} src Local image source value.
   */
  const onImageLoad = (src) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      image.onload = (e) => resolve(true);
      image.onerror = (e) => resolve(false);
    });
  };

  /**
   * @memberof Feedback
   * @name feedBackBody
   * @description Render the feedback body for the feedback component.
   * @class
 * @component
   */
  const feedBackBody = () => {
    const textBodyContent = {
      ...AppString.feedback.paragraphs,
      kundService: AppString.feedback.kundService,
    };
    return (
      <div className={classes.paper}>
        <RenderHeading classes={classes} content={AppString.feedback.title} />
        <RenderTextBody
          classes={classes}
          content={textBodyContent}
          externalLink={ExternalLinks.kundService}
        />
        <br />
        <RenderTextField
          classes={classes}
          content={AppString.feedback.textField}
          onHandleText={onHandleText}
          error={state.error}
        />
        <div className={classes.fileInputTitle}>
          <RenderScreenShot
            classes={classes}
            content={AppString.feedback.screenShot}
            onFileHandle={onFileHandle}
            fileSizeError={state.fileSizeError}
            filename={state.filename}
          />
        </div>
        <div className={classes.feedbackFooterContainer}>
          <RenderFeedbackFooter
            classes={classes}
            content={AppString.feedback.footer}
            onSubmit={onSubmit}
            handleClose={handleClose}
          />
        </div>
      </div>
    );
  };

  /**
   * @memberof Feedback
   * @name feedbackSuccessBody
   * @description Render the feedback success for the feedback component.
   * @class
 * @component
   */
  const feedbackSuccessBody = () => {
    return (
      <div className={`${classes.paper} ${classes.successPaper}`}>
        <RenderFeedbackSuccess
          classes={classes}
          content={AppString.feedback.success}
          successIcon={Brand}
          handleClose={handleClose}
        />
      </div>
    );
  };

  return (
    <>
      <Modal
        className={classes.feedbackContainer}
        open={feedbackModalData.isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {feedbackModalData?.isSuccess ? feedbackSuccessBody() : feedBackBody()}
      </Modal>
      <AppSpinner showLoader={reportFunctionData.isLoading} />
    </>
  );
};

export default Feedback;
