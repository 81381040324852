import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { API_CONSTANTS, initialState } from "../../utils";

/**
 * @category Redux
 * @subcategory books
 * @namespace slice:getBuyBookLinkSlice
 * @description getBuyBookLink is slice for getting buy link of book.
 */

/**
 * @memberof slice:getBuyBookLinkSlice
 * @method getBuyBookLink
 * @async
 * @description Rest api to get buy link of book.
 * @param {string} isbnNumber Book isbnNumber for the data.
 *
 */

export const getBuyBookLink = createAsyncThunk(
  "books/getBuyBookLink",
  async (isbnNumber, { rejectWithValue, getState, dispatch }) => {
    try {
      // const token = getState().userToken.data.access_token;
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get(`api/Book/bookstore/buy-book/${isbnNumber}`);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data.data;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getBuyBookLinkSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getBuyBookLinkSlice = createSlice({
  name: "getBuyBookLink",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBuyBookLink.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getBuyBookLink.fulfilled, (state, action) => {
      state.data = action.payload;
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getBuyBookLink.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getBuyBookLinkSlice
     * @method getBuyBookLinkClearData
     * @description Clear the getBuyBookLinkSlice.
     */
    getBuyBookLinkClearData: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { getBuyBookLinkClearData } = getBuyBookLinkSlice.actions;

export default getBuyBookLinkSlice.reducer;
