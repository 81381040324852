import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import { clearUserUniqueId } from "./saveUserUniqueIdSlice";
import { UserSession } from "../../../config/userManager";
import { clearHighlightCachedData } from "../highlight/getHighlightById";
import { clearNotesCachedData } from "../notes/getNotesById";
import { clearBookmarkCachedData } from "../bookmark/getBookmarkById";
import { clearGetBookCachedData } from "../bookReaderSlice/getBookByIdSlice";
import { clearBookLocationsWithCache } from "../bookReaderSlice/bookLocationsSlice";

/**
 * @category Redux
 * @subcategory userUniqueIdSlice
 * @namespace slice:deleteUserUniqueIdSlice
 * @description deleteUserUniqueIdSlice is slice for deleting the user unique id.
 */

/**
 * @memberof slice:deleteUserUniqueIdSlice
 * @method deleteUserUniqueId
 * @async
 * @description Rest api for deleting the user unique id.
 * @param {Object} headerData {userUniqueId:string}
 * @param {Object} param {type:string,userUniqueId:string}
 */

export const deleteUserUniqueId = createAsyncThunk(
  "user/deleteUserUniqueId",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = data?.accessToken
        ? data.accessToken
        : WebSession()?.access_token;
      const { headerData, param } = data;
      const _userUniqueId = headerData?.userUniqueId
        ? headerData?.userUniqueId
        : "";

      //clear cachedData
      dispatch(clearBookmarkCachedData());
      dispatch(clearHighlightCachedData());
      dispatch(clearNotesCachedData());
      dispatch(clearGetBookCachedData());
      dispatch(clearBookLocationsWithCache());

      const response = await myAxios(
        token,
        _userUniqueId === UserSession.sessionOverride
          ? _userUniqueId
          : getState().saveUserUniqueId.userUniqueId
      ).delete(
        `api/account/sessionInfo?type=${
          param?.type ? param?.type : ""
        }&userUniqueId=${param.userUniqueId ? param.userUniqueId : ""}`
      );
      dispatch(clearUserUniqueId());
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data?.data;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

/**
 * @memberof slice:deleteUserUniqueIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const deleteUserUniqueIdSlice = createSlice({
  name: "deleteUserUniqueId",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteUserUniqueId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteUserUniqueId.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.data = action.payload?.filter((val) => !val.articleId);
      }
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(deleteUserUniqueId.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default deleteUserUniqueIdSlice.reducer;
