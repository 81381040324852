import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MOBILE_PROMOTION_EXPIRY } from "../../../config";

/**
 * @category Redux
 * @subcategory mobilePromotionSlice
 * @namespace slice:mobilePromotionSlice
 * @description mobilePromotionSlice is slice for handling the mobile promotion banner.

/**
 * @memberof slice:mobilePromotionSlice
 * @method mobilePromotion
 * @async
 * @description Rest api for handling the mobile promotion banner.
 */

export const mobilePromotion = createAsyncThunk(
  "user/mobilePromotion",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().mobilePromotion;
      if (!state.cachedDate) {
        return rejectWithValue("no cached date");
      }
      const currentDate = new Date();
      const cachedDate = new Date(state.cachedDate);
      if (currentDate < cachedDate) {
        return true;
      }
      return rejectWithValue("expired");
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

/**
 * @memberof slice:mobilePromotionSlice
 * @name initialState
 * @description Initial slice state
 * @property {boolean} show Toggle mobile promotion banner.
 * @property {Object} cachedDate Cache the response.
 */
const initialState = {
  show: false,
  cachedDate: null,
};

export const mobilePromotionSlice = createSlice({
  name: "mobilePromotion",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(mobilePromotion.pending, (state) => {});

    builder.addCase(mobilePromotion.fulfilled, (state, action) => {
      state.show = false;
    });

    builder.addCase(mobilePromotion.rejected, (state, action) => {
      state.show = true;
    });
  },
  reducers: {
    /**
     * @memberof slice:mobilePromotionSlice
     * @method hideMobilePromotionDialog
     * @description Set the next date for showing the mobile promotion banner and hide the current.
     */
    hideMobilePromotionDialog: (state, action) => {
      state.show = false;
      const date = new Date();
      date.setDate(date.getDate() + MOBILE_PROMOTION_EXPIRY);
      state.cachedDate = date;
    },
    /**
     * @memberof slice:mobilePromotionSlice
     * @method clearMobilePromotionDialog
     * @description Clear the mobilePromotionSlice.
     */
    clearMobilePromotionDialog: (state, action) => {
      state.show = false;
    },
  },
});

export const { hideMobilePromotionDialog, clearMobilePromotionDialog } =
  mobilePromotionSlice.actions;

export default mobilePromotionSlice.reducer;
