import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { API_CONSTANTS, initialState } from "../../utils";
import { WebSession } from "../../utils/webSession";
import { URI } from "../../../config";
import { UserSession } from "../../../config/userManager";

/**
 * @category Redux
 * @subcategory searchHistory
 * @namespace slice:autoCompleteSlice
 * @description autoCompleteSlice is slice for getting text suggestions.
 */

/**
 * @memberof slice:autoCompleteSlice
 * @method autoComplete
 * @async
 * @description Rest api for posting new user search text.
 * @param {string} text user searched text.
 * @param {string} bookId book id.
 */

export const autoComplete = createAsyncThunk(
  "user/autoComplete",
  async ({ text, bookId }, { rejectWithValue, getState, dispatch, signal }) => {
    try {
      const token = WebSession()?.access_token;
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `api/search/text/auto-complete?search=${text}&bookId=${
          bookId ? bookId : ""
        }`,
        {
          baseURL: URI.BASE_URL,
          headers: {
            Authorization: "Bearer " + token,
            "user-unique-id": UserSession.sessionOverride,
          },
          cancelToken: source.token,
        }
      );
      signal.removeEventListener("abort", null);
      if (response.status === 200) {
        if (
          response.data.status.statusCode === 200 &&
          getState().searchHistory.searchText &&
          getState().searchHistory.searchText?.length > 2
        ) {
          return response.data.data;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      signal.removeEventListener("abort", null);
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:autoCompleteSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const autoCompleteSlice = createSlice({
  name: "autoComplete",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(autoComplete.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(autoComplete.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.hasError = false;
      state.error = null;
      state.data = action.payload;
    });

    builder.addCase(autoComplete.rejected, (state, action) => {
      if (action.payload) {
        state.hasData = false;
        state.error = action.payload;
        state.hasError = true;
        state.isLoading = false;
        state.data = null;
      }
    });
  },
  reducers: {
    /**
     * @memberof slice:autoCompleteSlice
     * @method clearAutoComplete
     * @description Clear the autoCompleteSlice.
     */
    clearAutoComplete: (state, action) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.error = null;
      state.data = null;
    },
  },
});

export const { clearAutoComplete } = autoCompleteSlice.actions;

export default autoCompleteSlice.reducer;
