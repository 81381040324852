import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  toolTip: {
    boxShadow: theme.shadows[1],
    maxWidth: (props) => props.maxWidth,
    margin: 0,
  },
  popper: {
    zIndex: 3000,
  },
}));

/**
 * @category UiComponents
 * @subcategory Common
 * @namespace AppTooltip
 * @description Application tooltip component.
 * @param {string} key Key for the component.
 * @param {string} title Title text for tooltip
 * @param {React.FC|string} children Children can be component or text.
 * @param {int} maxWidth Maximum width or tooltip
 * @param {string} placement Placement of the tooltip
 * @param {boolean} arrow Toggle flag for the arrow in tooltip
 * @param {int} enterTouchDelay Enter touch delay
 * @param {boolean} followCursor Toggle flag for the follow cursor in tooltip
 * @param {boolean} disableInteractive Flag to make tooltip interactive.
 * @class
 * @component
 */
export default function AppTooltip({
  key,
  title,
  children,
  maxWidth,
  placement,
  arrow,
  enterTouchDelay,
  followCursor,
  disableInteractive,
}) {
  const tooltipClasses = useStyles({ maxWidth });

  return (
    <Tooltip
      title={title}
      classes={{
        tooltip: tooltipClasses.toolTip,
        popper: tooltipClasses.popper,
      }}
      placement={placement}
      arrow={arrow}
      enterTouchDelay={enterTouchDelay}
      followCursor={followCursor}
      key={key}
      disableInteractive={disableInteractive}
    >
      {children}
    </Tooltip>
  );
}

AppTooltip.defaultProps = {
  title: "",
  classes: "",
  maxWidth: 250,
  placement: "bottom",
  enterTouchDelay: 700,
  arrow: false,
  followCursor: false,
  key: "appTooltip",
  disableInteractive: true,
};
