import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import { SpinnerCircularFixed } from "spinners-react";

const useStyles = makeStyles((theme) => ({
  progress: {
    color: theme.palette.common.white,
  },
  progressDiv: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1500,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

/**
 * @category UiComponents
 * @subcategory Common
 * @namespace AppSpinner
 * @description Application loader.
 * @param {boolean} showLoader Component toggle flag
 * @class
 * @component
 */
const AppSpinner = ({ showLoader }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      {showLoader ? (
        <div className={classes.progressDiv}>
          <SpinnerCircularFixed
            size={100}
            color={theme.palette.common.white}
            secondaryColor={theme.palette.common.dashBoardBorderGrey}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

AppSpinner.propTypes = {
  showLoader: PropTypes.bool.isRequired,
};

export default AppSpinner;
