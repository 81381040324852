import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory highlight
 * @namespace slice:addHighlightsSlice
 * @description addHighlightsSlice is slice for posting new book highlights.
 */

/**
 * @memberof slice:addHighlightsSlice
 * @method addHighlights
 * @async
 * @description Rest api for posting new book highlights.
 * @param {string} bookId Book id for the highlight.
 * @param {string} cfi Book cfi id for the highlight.
 * @param {string} chapterName Book chapterName for the highlight.
 * @param {string} color color for the highlight.
 * @param {string} text Book text for the highlight.
 */

export const addHighlights = createAsyncThunk(
  "book/addHighlights",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post(`api/highlight`, data);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return {
            cfi: data.cfi,
            color: data.color,
          };
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:addHighlightsSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const addHighlightsSlice = createSlice({
  name: "addHighlights",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addHighlights.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addHighlights.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(addHighlights.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:addHighlightsSlice
     * @method clearAddHighlights
     * @description Clear the addHighlightsSlice.
     */
    clearAddHighlights: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearAddHighlights } = addHighlightsSlice.actions;

export default addHighlightsSlice.reducer;
