import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory menuPopupSlice
 * @namespace slice:menuPopupSlice
 * @description menuPopupSlice is slice for handling book menu in dashboard.
 */

/**
 * @memberof slice:menuPopupSlice
 * @name defaultData
 * @description Default data for dashboardBookCardMenu
 * @property {boolean} isOpen Toggle book menu.
 * @property {Object} anchor Toggle book menu.
 * @property {string} bookId Book id.
 * @property {string} bookShelveId Bookshelf id.
 */

const defaultData = {
  isOpen: false,
  anchor: null,
  bookId: "",
  bookShelveId: "",
};

/**
 * @memberof slice:menuPopupSlice
 * @name initialState
 * @description Initial slice state
 * @property {Object} dashboardBookCardMenu {...defaultData}
 */

const initialState = {
  dashboardBookCardMenu: defaultData,
};

export const menuPopupSlice = createSlice({
  name: "menuPopup",
  initialState,
  reducers: {
    /**
     * @memberof slice:menuPopupSlice
     * @method dashboardBookCardMenu
     * @description Toggle the book menu.
     * @param {Object} payload {isOpen:boolean, anchor:Object, bookId:string, bookShelveId:string}
     */
    dashboardBookCardMenu: (state, action) => {
      state.dashboardBookCardMenu.isOpen = action.payload.isOpen;
      state.dashboardBookCardMenu.anchor = action.payload.anchor;
      state.dashboardBookCardMenu.bookId = action.payload.bookId;
      state.dashboardBookCardMenu.bookShelveId = action.payload.bookShelveId;
    },
    /**
     * @memberof slice:menuPopupSlice
     * @method clearDashboardBookCardMenu
     * @description Clear the menuPopupSlice.
     */
    clearDashboardBookCardMenu: (state, action) => {
      state.dashboardBookCardMenu.isOpen = false;
      state.dashboardBookCardMenu.anchor = null;
      state.dashboardBookCardMenu.bookId = "";
      state.dashboardBookCardMenu.bookShelveId = "";
    },
  },
});

export const { dashboardBookCardMenu, clearDashboardBookCardMenu } =
  menuPopupSlice.actions;

export default menuPopupSlice.reducer;
