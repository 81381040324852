import { createSlice } from "@reduxjs/toolkit";
import { AssetType, NotebookType } from "../../../config";

/**
 * @category Redux
 * @subcategory bookReaderSlice
 * @namespace slice:bookReaderSlice
 * @description book data redux.
 */

/**
 * @memberof slice:bookReaderSlice
 * @name initialState
 * @description Initial slice state
 * @property {boolean} tocPanel Toggle toc panel
 * @property {boolean} notebookPanel Toggle notebook panel
 * @property {Array<Object>} tocData Toc data
 * @property {boolean} fullscreen Toggle fullscreen
 * @property {boolean} searchPanel Toggle search panel
 * @property {boolean} searchFlag Is search made
 * @property {string} notesText Notes text
 * @property {int} notebookTabIndex Notebook index
 * @property {boolean} bookNotFound Toggle book not found modal.
 * @property {boolean} searchInputFocus Toggle search inout focus
 * @property {string} assetType Clicked asset type
 * @property {Object} assetContent Clicked asset content
 * @property {boolean} BookResume toggle book resume snackbar
 * @property {string} bookResumeLoc Book resume cfi location
 * @property {string|null} notesCfiId Notes cfi id
 * @property {string} notesCfiType Notes cfi type
 */

const initialState = {
  tocPanel: false,
  notebookPanel: false,
  tocData: [],
  fullscreen: false,
  searchPanel: false,
  searchFlag: false,
  notesText: "",
  editNotesFlag: false,
  notesData: null,
  notebookTabIndex: 0,
  bookNotFound: false,
  searchInputFocus: false,

  //asset modal,
  assetType: AssetType.NONE,
  assetContent: null,

  //bookResume
  bookResume: false,
  bookResumeLoc: "",

  //notes click
  notesCfiId: null,
  notesCfiType: NotebookType.notes, //highlights

};
// 0 = Highlight ,1 = bookmark, 2=notes
/**
 * @memberof slice:bookReaderSlice
 * @method getChapterArray
 * @description Get the chapter array from the toc data.
 * @param {string} main Panel you want to toggle
 * @param {Array<Object>} others Rest of other panels
 * @param {Object} state Redux state.
 */
//tab index
//if open is made open, close remaining two
const toggleOthers = (main, others, state) => {
  state[main] = !state[main];
  others.map((val) => {
    if (state[val]) {
      state[val] = false;
    }
    return val;
  });
};

export const bookReaderSlice = createSlice({
  name: "bookReader",
  initialState,
  reducers: {
    /**
     * @memberof slice:bookReaderSlice
     * @method setTocPanel
     * @description Toggle toc panel.
     * @param {boolean} payload Toc panel flag.
     *
     */
    setTocPanel: (state, action) => {
      state.tocPanel = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method toggleTocPanel
     * @description Advance toggle toc panel.
     *
     */
    toggleTocPanel: (state, action) => {
      toggleOthers("tocPanel", ["notebookPanel", "searchPanel"], state);
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setNotebookPanel
     * @description Toggle notebook panel flag.
     * @param {boolean} payload notebook panel flag.
     *
     */
    setNotebookPanel: (state, action) => {
      state.notebookPanel = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method toggleNotebookPanel
     * @description Advance toggle notebook panel.
     *
     */
    toggleNotebookPanel: (state, action) => {
      toggleOthers("notebookPanel", ["tocPanel", "searchPanel"], state);
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setFullscreen
     * @description Toggle fullscreen flag.
     * @param {boolean} payload Fullscreen flag.
     *
     */
    setFullscreen: (state, action) => {
      state.fullscreen = action.payload;
      state.tocPanel = false;
      state.searchPanel = false;
      state.notebookPanel = false;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method toggleFullscreen
     * @description Advance toggle fullscreen panel.
     *
     */
    toggleFullscreen: (state, action) => {
      state.fullscreen = !state.fullscreen;
      state.tocPanel = false;
      state.searchPanel = false;
      state.notebookPanel = false;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setSearchPanelWithFlag
     * @description Advance toggle search panel.
     *
     */
    setSearchPanelWithFlag: (state, action) => {
      state.searchFlag = !state.searchFlag;
      state.tocPanel = false;
      state.searchPanel = true;
      state.notebookPanel = false;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setSearchPanel
     * @description Toggle search panel flag.
     * @param {boolean} payload Search panel flag.
     *
     */
    setSearchPanel: (state, action) => {
      state.searchPanel = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method toggleSearchPanel
     * @description Advance toggle search panel.
     */
    toggleSearchPanel: (state, action) => {
      toggleOthers("searchPanel", ["notebookPanel", "tocPanel"], state);
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setEditNote
     * @description Set the edit notes
     * @param {Object} payload {notesCfiId:string,notesText:string,notesData:object}
     *
     */
    setEditNote: (state, action) => {
      state.notebookTabIndex = 0;
      state.notesCfiType = NotebookType.notes;
      state.notesCfiId = action.payload.notesCfiId;
      state.notesText = action.payload.notesText;
      state.notesData = action.payload.notesData;
      state.editNotesFlag = true;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method onNotesTextChange
     * @description On notes text change
     * @param {string} payload notes text.
     *
     */
    onNotesTextChange: (state, action) => {
      state.notesText = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setNotebookTabIndex
     * @description Set notebook tab index
     * @param {int} payload notebook tab index
     *
     */
    setNotebookTabIndex: (state, action) => {
      state.notebookTabIndex = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setBookReaderNotesClick
     * @description On notes click from notebook panel.
     * @param {Object} payload {markId:string,notesType:string, notebookTabIndex:int}
     *
     */
    setBookReaderNotesClick:(state,action) => {
      state.notesCfiId = action.payload.markId;
      state.notesCfiType = action.payload.notesType;
      state.tocPanel = false;
      state.searchPanel = false;
      state.notebookPanel = true;
      state.notebookTabIndex = action.payload.notebookTabIndex;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method onNoteClear
     * @description Notes click clear state.
     *
     */
    onNoteClear: (state, action) => {
      state.notesText = "";
      state.notesCfiId = null;
      state.notesCfiType = NotebookType.notes;
      state.notesData = null;
      state.editNotesFlag = false;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method closeAllPanel
     * @description All panel close
     *
     */
    closeAllPanel: (state, action) => {
      state.tocPanel = false;
      state.notebookPanel = false;
      state.searchPanel = false;
      state.editNotesFlag = false;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setBookNotFound
     * @description Toggle no book found flag.
     * @param {boolean} payload Book not found
     *
     */
    setBookNotFound: (state, action) => {
      state.bookNotFound = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setSearchInputFocus
     * @description Search input focus flag
     * @param {Object} payload Search input focus
     *
     */
    setSearchInputFocus: (state, action) => {
      state.searchInputFocus = action.payload;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setAssetModel
     * @description Toggle asset model and content. 
     * @param {Object} payload {assetType:string,assetContent:Object}
     *
     */
    setAssetModel: (state, action) => {
      state.assetType = action.payload.type;
      state.assetContent = action.payload.content;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method setBookResume
     * @description Set the book resume parameters
     * @param {Object} payload {bookResume:boolean,bookResumeLoc:string}
     *
     */
    setBookResume: (state, action) => {
      state.bookResume = action.payload.isOpen;
      state.bookResumeLoc = action.payload.loc;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method closeAssetModel
     * @description Close book asset modal.
     *
     */
    closeAssetModel: (state, action) => {
      state.assetType = AssetType.NONE;
      state.assetContent = null;
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method closeBookResume
     * @description Close book resume snack bar.
     *
     */
    closeBookResume: (state, action) => {
      state.bookResume = false;
      state.bookResumeLoc = "";
    },
    /**
     * @memberof slice:bookReaderSlice
     * @method clearBookReader
     * @description Clear the bookReaderSlice
     * @param {Object} payload {bookId:string,currentLocation:string}
     *
     */
    clearBookReader: (state, action) => {
      state.tocPanel = false;
      state.notebookPanel = false;
      state.tocData = [];
      state.fullscreen = false;
      state.searchPanel = false;
      state.notesText = "";
      state.editNotesFlag = false;
      state.notesCfiId = null;
      state.notesCfiType = NotebookType.notes;
      state.notesData = null;
      state.notebookTabIndex = 0;
      state.bookNotFound = false;
      state.searchInputFocus = false;
      state.assetType = AssetType.NONE;
      state.assetContent = null;
      state.bookResume = false;
      state.bookResumeLoc = "";
    },
  },
});

export const {
  setTocPanel,
  toggleTocPanel,
  setNotebookPanel,
  toggleNotebookPanel,
  setFullscreen,
  toggleFullscreen,
  setSearchPanel,
  toggleSearchPanel,
  setEditNote,
  setNotebookTabIndex,
  onNotesTextChange,
  onNoteClear,
  setBookReaderNotesClick,
  clearBookReader,
  setSearchPanelWithFlag,
  closeAllPanel,
  setBookNotFound,
  setSearchInputFocus,
  setAssetModel,
  closeAssetModel,
  setBookResume,
  closeBookResume,
} = bookReaderSlice.actions;

export default bookReaderSlice.reducer;
