import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS, initialState } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";

/**
 * @category Redux
 * @subcategory bookShelfSlice
 * @namespace slice:getAllBooksByShelveIdSlice
 * @description getAllBooksByShelveIdSlice is slice for getting all books for bookshelf.
 */

/**
 * @memberof slice:getAllBooksByShelveIdSlice
 * @method getAllBooksByShelveId
 * @async
 * @description Rest api to get all books for bookshelf.
 * @param {string} params Bookshelf id
 */

export const getAllBooksByShelveId = createAsyncThunk(
  "books/getAllBooksByShelveId",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      // const token = getState().userToken.data.access_token;
      const token = WebSession()?.access_token;
      let response = null;
      response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get(`api/bookshelf/${id}`);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data.data;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

/**
 * @memberof slice:getAllBooksByShelveIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getAllBooksByShelveIdSlice = createSlice({
  name: "getAllBooksByShelveId",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllBooksByShelveId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllBooksByShelveId.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getAllBooksByShelveId.rejected, (state, action) => {
      state.hasData = false;
      state.error = action;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:getAllBooksByShelveIdSlice
     * @method clearBookShelveIdData
     * @description Clear the getAllBooksByShelveIdSlice.
     */
    clearBookShelveIdData: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearBookShelveIdData } = getAllBooksByShelveIdSlice.actions;

export default getAllBooksByShelveIdSlice.reducer;
