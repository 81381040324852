import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory dashboardAccordionSlice
 * @namespace slice:dashboardAccordionSlice
 * @description dashboardAccordionSlice is slice for handling the bookshelf accordion.
 */

/**
 * @memberof slice:dashboardAccordionSlice
 * @name initialState
 * @description Initial slice state
 * @property {Object} bookshelfId Store the accordion details.
 */
const initialState = {
  bookshelfId: {},
};

export const dashboardAccordionSlice = createSlice({
  name: "dashboardAccordion",
  initialState,
  reducers: {
    /**
     * @memberof slice:dashboardAccordionSlice
     * @method setDashboardAccordionAccordion
     * @description Set the accordion data with ids
     * @param {Object} payload { bookShelveId:string, isExpanded:boolean }
     */
    setDashboardAccordionAccordion: (state, action) => {
      const { bookShelveId, isExpanded } = action.payload;
      state.bookshelfId[bookShelveId] = isExpanded;
    },
    /**
     * @memberof slice:dashboardAccordionSlice
     * @method clearAllDashboardAccordion
     * @description Clear the dashboardAccordionSlice.
     */
    clearAllDashboardAccordion: (state) => {
      state.bookshelfId = {};
    },
  },
});

export const { setDashboardAccordionAccordion, clearAllDashboardAccordion } =
  dashboardAccordionSlice.actions;

export default dashboardAccordionSlice.reducer;
