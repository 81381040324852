import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { API_CONSTANTS, initialState } from "../../utils";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory notes
 * @namespace slice:updateNotesByIdSlice
 * @description updateNotesByIdSlice is slice for posting new notes.
 */

/**
 * @memberof slice:updateNotesByIdSlice
 * @method updateNotesById
 * @async
 * @description Rest api for posting new notes.
 * @param {int} id Notes id for the notes.
 * @param {string} bookId Book id for the notes.
 * @param {string} cfi Book cfi id for the notes.
 * @param {string} chapterName Book chapterName for the notes.
 * @param {string} color color for the notes.
 * @param {string} text Book text for the notes.
 */

export const updateNotesById = createAsyncThunk(
  "book/updateNotesById",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).put(`api/notes`, data);
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          dispatch(getAllNotebookReload());
          return response.data.status.statusMessage;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:updateNotesByIdSlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 * @property {boolean} showPopup Toggle notes popup menu.
 * @property {string} bookId Book id.
 */

export const updateNotesByIdSlice = createSlice({
  name: "updateNotesById",
  initialState: {
    ...initialState,
    showPopup: false,
    bookId: "",
  },
  extraReducers: (builder) => {
    builder.addCase(updateNotesById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateNotesById.fulfilled, (state, action) => {
      state.hasData = true;
      state.data = action.payload;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(updateNotesById.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:updateNotesByIdSlice
     * @method showPopup
     * @description Show notes editor popup menu.
     * @param {string} payload book id.
     */
    showPopup: (state, action) => {
      state.showPopup = true;
      state.bookId = action.payload;
    },
    /**
     * @memberof slice:updateNotesByIdSlice
     * @method clearPopup
     * @description Clear notes editor popup menu.
     */
    clearPopup: (state, action) => {
      state.showPopup = false;
      state.bookId = "";
    },
    /**
     * @memberof slice:updateNotesByIdSlice
     * @method clearUpdateNotesById
     * @description Clear the updateNotesByIdSlice.
     */
    clearUpdateNotesById: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { clearUpdateNotesById, showPopup, clearPopup } =
  updateNotesByIdSlice.actions;

export default updateNotesByIdSlice.reducer;
