import { createSlice } from "@reduxjs/toolkit";

/**
 * @category Redux
 * @subcategory userUniqueIdSlice
 * @namespace slice:saveUserUniqueIdSlice
 * @description getUserUniqueIdSlice is slice for getting the user unique id.
 */

/**
 * @memberof slice:saveUserUniqueIdSlice
 * @name initialState
 * @description Initial slice state
 * @property {string} userUniqueId User unique id.
 * @property {string} userId User id.
 * @property {string} updatedTime Updated time.
 */
const initialState = {
  userUniqueId: "",
  userId: "",
  updatedTime: "",
};

export const saveUserUniqueIdSlice = createSlice({
  name: "saveUserUniqueId",
  initialState,
  reducers: {
    /**
     * @memberof slice:saveUserUniqueIdSlice
     * @method setUserUniqueId
     * @description Set the user unique id for passing to header.
     * @param {Object} payload {userUniqueId:string, userId:string, updatedTime:string}.
     */
    setUserUniqueId: (state, action) => {
      state.userUniqueId = action.payload.userUniqueId;
      state.userId = action.payload.userId;
      state.updatedTime = action.payload.updatedTime;
    },
    /**
     * @memberof slice:saveUserUniqueIdSlice
     * @method clearUserUniqueId
     * @description Clear the saveUserUniqueIdSlice.
     */
    clearUserUniqueId: (state, action) => {
      state.userUniqueId = "";
      state.userId = "";
      state.updatedTime = "";
    },
  },
});

export const { setUserUniqueId, clearUserUniqueId } =
  saveUserUniqueIdSlice.actions;

export default saveUserUniqueIdSlice.reducer;
