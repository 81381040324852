import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { myAxios } from "../../utils/myAxios";
import { setSessionLogout } from "../auth/logoutSlice";
import { WebSession } from "../../utils/webSession";
import { initialState } from "../../utils";
import { setBookMeta } from "../bookReaderSlice/getBookByIdSlice";

/**
 * @category Redux
 * @subcategory books
 * @namespace slice:getBookMetaById
 * @description getBookMetaById is slice for getting the meta data until the book get processed.
 */

/**
 * @memberof slice:getBookMetaById
 * @method getBookMetaById
 * @async
 * @description Rest api to get book meta data.
 * @param {string} bookId Book id for the data.
 * @param {string=} articleId Book article id for the data.
 *
 */

export const getBookMetaById = createAsyncThunk(
  "books/getBookMetaById",
  async ({ bookId, articleId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).get(`api/Book/metadata/${bookId}`);
      if (
        response.status === 200 &&
        response.data.status.statusCode === 200 &&
        response.data?.book &&
        response.data?.book?.opfPath
      ) {
        const id = response.data?.book?.id;
        const bookType = response.data?.book?.bookType;
        const title = response.data?.book?.title;
        const opfPath = response.data?.book?.opfPath;
        dispatch(
          setBookMeta({
            book: { id, title, bookType, opfPath },
            bookId,
            articleId,
          })
        );
        return { id, title, bookType, opfPath };
      }
      return rejectWithValue("error on api");
    } catch (err) {
      if (err?.response?.status === 429 || err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue("401");
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:getBookMetaById
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial slice state
 */

export const getBuyBookLinkSlice = createSlice({
  name: "getBookMetaById",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBookMetaById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getBookMetaById.fulfilled, (state, action) => {
      state.data = action.payload;
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(getBookMetaById.rejected, (state, action) => {
      state.hasData = false;
      state.data = null;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {},
});

export default getBuyBookLinkSlice.reducer;
